.payment_Op {
  position: relative;
  top: 80px;
  background: #f9f8ff;
  /* display: grid; */
  grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
  gap: 25px;
}

.payment_Op_B_S {
  width: 100%;
  max-width: 1400px;
  padding: 25px 0 70px 0;
  margin: auto;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;



  /* width: 100%;
  padding: 50px 25px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
  gap: 25px;
  max-width: 1400px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; */
}

.pay_Main_Head {
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 45px;
  font-weight: 600;
  line-height: 70px;
  width: 100%;
  height: 110px;
}

.payment_Op_B {
  width: 300px;
  height: 615px;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 32px;
  box-shadow: -8px 4px 4px 0px rgba(64, 113, 244, 0.25) inset,
    0px 4px 4px 0px rgba(64, 113, 244, 0.25);
  transform-style: preserve-3d;
  transition: transform 0.9s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.pay_B_T {
  width: 100%;
  height: 100px;
  background: #093EA4;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
}

.pay_In {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 65px;
  font-weight: 500;
  opacity: 19%;
}

.pay_PlaN {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}

.pay_B_C {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p_B_C_T {
  width: 100px;
  height: 100px;
  border: 3px solid #ffff;
  border-radius: 50%;
  position: fixed;
  top: 50px;
  z-index: 999;
}

.p_B_C_T_ {
  width: 87px;
  height: 90px;
  background: #ffff;
  border-radius: 50%;
  position: fixed;
  left: 106px;
  top: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #093EA4;
}

.p_B_C {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 25px 25px 0 25px;
  flex-direction: column;
}

.p_B_C_He {
  color: #9C9C9C;
  font-size: 22px;
  font-weight: 600;
  position: relative;
  z-index: 9999;
  text-align: center;
  margin: unset;
}

.sub_H_Pay {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #9C9C9C;
}

.h_Pay_Li {
  display: flex;
  gap: 20px;
}

.h_Pay_Li svg {
  font-size: 25x;
  /* color: #093EA4; */
  color: #34B641;
}

.h_PayH {
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
}

.pay_B_F {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 30px;
}

.pay_B_F button {
  width: 135.5px;
  height: 40px;
  border-radius: 3px;
  font-weight: 700;
  border: none;
  background: #093EA4;
  color: #ffffff;
}

.pay_Dot_Sec {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 25px;
}

@media (max-width: 1339px) {
  .payment_Op_B_S {
    justify-content: center;
    padding: 25px 25px 70px 25px;
    gap: 51px;
  }
}

@media (max-width: 800px) {
  .pay_Main_Head {
    font-size: 35px;
    height: 100px;
  }
}

@media (max-width: 557px) {

  .p_B_C_He {
    font-size: 20px;
  }

  .h_PayH {
    font-size: 18px;
  }

  .pay_Main_Head {
    font-size: 25px;
    height: 70px;
  }

  .payment_Op_B {
    height: 550px;
  }

  .payment_Op_B_S {
    padding: 25px 10px 50px 10px;
  }

  .sub_H_Pay {
    font-size: 14px;
    gap: 15px;
  }
}


.a_Plan {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pLAN_Opc {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 70px 0;
}

.gap_10_Px {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plaN_Flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pLAN_Opc_Box {
  width: 80%;
  min-height: 150px;
  background: #ffff;
  padding: 20px 35px;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  gap: 20px;
}

.pLAN_Opc_Box_L {
  display: flex;
  gap: 50px;
  align-items: center;
}

.box_PlaN {
  font-size: 30px;
  font-weight: 600;
  color: #323232;
}

.box_NotE {
  font-size: 20px;
  font-weight: 500;
  color: #858585;
}

.box_PricE {
  font-size: 24px;
  font-weight: 600;
  color: #323232;
}

.custom-radio {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 56px !important;
}

.pLAN_Opc_Rad .p-highlight {
  border-color: transparent !important;
  background: #093EA4 !important;
}

.pLAN_Opc_Rad,
.pLAN_Opc_Rad .p-highlight,
.pLAN_Opc_Rad .p-radiobutton-box,
.pLAN_Opc_Rad .p-radiobutton-box:hover {
  width: 30px !important;
  height: 30px !important;
  box-shadow: unset !important;
}

.plaN_Det {
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.pLAN_Lrft {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.plaN_Det_No {
  width: 220px;
}

.pLAN_Right {
  width: 125px !important;
}


@media (max-width: 1000px) {

  .box_PlaN {
    font-size: 25px;
  }

  .box_NotE {
    font-size: 18px;
  }

  .box_PricE {
    font-size: 20px;
  }
}

@media (max-width: 800px) {

  .pLAN_Opc_Box {
    width: 100%;
    padding: 25px;
  }

}

@media (max-width: 575px) {
  .pLAN_Opc_Box_L {
    gap: 20px;
  }

  .plaN_Det {
    width: 100%;
    flex-direction: column;
    gap: 15px;
    display: flex;
    align-items: flex-start;
  }

  .pLAN_Right {
    width: auto !important;
  }

  .box_PlaN {
    font-size: 22px;
  }

  .box_NotE {
    font-size: 16px;
  }

  .box_PricE {
    font-size: 18px;
  }

  .pLAN_Opc_Rad,
  .pLAN_Opc_Rad .p-highlight,
  .pLAN_Opc_Rad .p-radiobutton-box,
  .pLAN_Opc_Rad .p-radiobutton-box:hover {
    width: 20px !important;
    height: 20px !important;
    box-shadow: unset !important;
  }

  .plaN_Det_No {
    font-size: 14px;
  }
}

@media (max-width: 420px) {

  .box_PlaN {
    font-size: 18px;
  }

  .box_NotE {
    font-size: 14px;
  }

  .box_PricE {
    width: calc(100% - 80px);
    font-size: 16px;
  }

  .plaN_Flex {
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .pLAN_Opc_Box_L {
    width: 100%;
  }

  .plaN_Flex {
    align-items: center;
  }

  .pLAN_Opc_Box {
    gap: unset;
    align-items: center;
    justify-content: center;
  }

  .ass_Plan {
    padding: 10px 0 0;
  }
}