 .comapny-chart-pie {
     height: 300px;
     width: 400px !important;
 }

 .comapny-chart-pie1 {
     height: 300px;
     width: 380px !important;
 }

 .form-control {
    width: 100% !important;
 }

 .profile-img ,.profile-contents , .pie-card-card {
    box-shadow: 0px 1px 6px 0px #00000040;
 }


 #state {
     width: 40px;
     padding: 5px;
     padding-top: 0px !important;
 }

 .input-box {
     width: 64px !important;
     color: #919191 !important;
     outline: none !important;
     font-size: 20px !important;
     font-weight: 600 !important;
 }

 .unique-com-profile {
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .input-box .p-inputtext {
     border-bottom-style: hidden !important;
     border-top: 1px solid #C8C8C8 !important;
     border-bottom: 1px solid #C8C8C8 !important;
     border-left: 1px solid #C8C8C8 !important;
     border-right: 1px solid #C8C8C8 !important;
     width: 64px !important;
     height: 36px !important;
 }

 .dropdown-box {
     border-top: 1px solid #C8C8C8 !important;
     border-bottom: 1px solid #C8C8C8 !important;
     border-left: 1px solid #C8C8C8 !important;
     border-right: 1px solid #C8C8C8 !important;
     margin-left: 10px !important;
     width: 150px !important;
     height: 36px !important;
     color: #919191 !important;
     outline: none !important;
     padding: 0px !important;
     font-size: 16px !important;
     font-weight: 500 !important;
 }

 .dropdown-box .p-placeholder {
     font-size: 16px !important;
     font-weight: 500 !important;
     color: #919191 !important;
 }

 .profile-page {
     padding: 0px !important;
 }

 .com-container.col-12 {
     padding: 0px !important;
 }

 .com-Adhoc {
     padding-bottom: 0px !important;
 }

 .chart-com {
     padding: 4rem !important;
 }
   

 @media (max-width: 1400px) {
     .pie-pie-pie {
         padding-bottom: 2rem !important;
     }

     .imgandcard.grid {
         display: flex !important;
         width: 100% !important;
     }

     .chart-com {
         padding: 0rem !important;
     }

     .com-profile-img.flex {
         display: grid !important;
     }

     .profile-content {
         display: flex !important;
         flex-wrap: wrap !important;
         justify-content: space-evenly !important;
         align-items: center !important;
         gap: 150px !important;
     }

 }

 @media (max-width: 1000px) {

     .profile-content {
         gap: 100px !important;
     }

     .profile-content h4 {
         font-size: 18px !important;
     }

     .profile-content p {
         font-size: 16px !important;
     }

     .edit-button {
         font-size: 16px !important;
     }

     .summary h4 {
         font-size: 16px !important;
     }

     .unique-users-com .col-fixed {
         font-size: 16px !important;
     }

     .input-box .p-inputtext {
         font-size: 16px !important;
     }

     .unique-text-base {
         font-size: 16px !important;
     }

     .dropdown-box .p-placeholder {
         font-size: 14px !important;
         font-weight: 400 !important;
     }
 }
 @media (max-width: 1000px) {
    .profile-content {
        gap: 50px !important;
    }
    .summary {
        display: grid !important;
    }
} 

 @media (max-width: 880px) {

     .profile-content {
         display: flex !important;
         justify-content: space-evenly !important;
         align-items: center !important;
         gap: 20px !important;
     }

     .summary {
         display: grid !important;
     }
 }

 @media (max-width: 770px) {
     .dropdown-box {
         height: 33px !important;
     }

     .chart-com.flex {
         display: block !important;
     }

     .profile-content h4 {
         font-size: 16px !important;
     }

     .profile-content p {
         font-size: 14px !important;
     }

     .edit-button {
         font-size: 14px !important;
     }

     .unique-users-com .col-fixed {
         font-size: 14px !important;
     }

     .dropdown-box .p-placeholder {
         font-size: 13px !important;
     }

     .input-box .p-inputtext {
         font-size: 14px !important;
         height: 33px !important;
     }

     .unique-text-base {
         font-size: 14px !important;
     }

     .dropdown-box .p-icon {
         height: 13px !important;
     }

 }

 @media (max-width: 749px) {
     .profile-content {
         display: flex !important;
         justify-content: space-around !important;
         align-items: center !important;
         gap: 100px !important;
     }
 }

 @media (min-width: 320px) and (max-width: 625px) {
     .profile-content {
         display: flex !important;
         justify-content: space-evenly !important;
         align-items: center !important;
         gap: 20px !important;
     }
 } 

 @media (min-width: 320px) and (max-width: 455px) {
     .profile-content {
         display: grid !important;
         justify-content: space-evenly !important;
         align-items: center !important;
         gap: 0px !important;
     }
 }

 @media (min-width: 405px) and (max-width: 480px) {
     .input-box {
         width: 30px !important;
     }

     .input-box .p-inputtext {
         width: 37px !important;
         display: flex !important;
         justify-content: center !important;
     }

     .dropdown-box {
         width: 100px !important;
     }
 }
 @media (max-width: 405px) {
    .summary {
        padding-left: 10px;
    }
    .unique-com-profile.col {
       flex-grow: 0 !important;
       flex-basis: 0;
       padding: 0.5rem;
   }
}
 @media (max-width: 375px) {     
     .logo-com p {
        font-size: 1.5rem !important;
        font-weight: 600 !important;
     }
 }

 @media (min-width: 320px) and (max-width: 574px) {

     .comapny-chart-pie {
         height: 275px !important;
         width: 250px !important;
     }

     .comapny-chart-pie1 {
         height: 300px !important;
         width: 250px !important;
     }
 }

 @media (min-width: 575px) and (max-width: 759px) {
     .comapny-chart-pie {
         height: 273px !important;
         width: 300px !important;
     }

     .comapny-chart-pie1 {
         height: 300px !important;
         width: 320px !important;
     }
 }

 @media (min-width: 760px) and (max-width: 920px) {
     .comapny-chart-pie {
         height: 300px !important;
         width: 250px !important;
     }
 }