#userprofile-client-card .p-button:hover {
    background-image: none !important;
    background-color: #124A99;
}

.rect-a1 {
    position: absolute;
    left: 0%;
    top: 78%;
    height: 5rem;
}

.rect-b1 {
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 0;
    height: 12rem;
}

.rectangle-c1 {
    position: absolute;
    right: 0%;
    top: 18%;
    z-index: 1;
    height: 10rem;
}

#user-info .p-avatar img {
    height: 100%;
    border-radius: 50% !important;
}

#user-info .p-avatar-circle img {
    border-radius: 0%;
}

.user-file input[type=file] {
    max-width: 100%;
    color: #444;
    padding: 5px;
    padding-right: 0% !important;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
}

.user-file input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #124A99;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

.p-dialog .p-dialog-footer {
    padding: 0px !important;
}

.p-dialog-header-icons {
    position: relative;
    /* top: 5px; */
}

.user-file input[type=file]::file-selector-button:hover {
    background: #124A99;
}

.up-avtr {
    width: 37% !important;
    max-height: 100% !important;
}

#user-info .p-avatar {
    width: 8.5rem !important;
    height: 8.5rem !important;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
}

@media (max-width: 400px) {
    div#pr_id_82 {
        width: 17rem !important;
    }
}

@media (min-width: 320px) and (max-width: 1130px) {
    .user-profile-content.flex {
        display: flex !important;
        flex-direction: column;
        align-content: space-between;
        flex-wrap: wrap;
        gap: 17px;
        justify-content: center;
        align-items: stretch;
    }

    div#user-info {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        /* justify-content: center; */
        align-items: stretch;
        width: 100% !important;
    }

    div#user-profile {
        width: 96%;
        margin-right: 0 !important;
        margin-left: 15px !important;
        margin-top: 0 !important;
    }

    .card.card-prof.w-full.bg-white {
        margin-bottom: 44px;
        margin-top: 38px;
        position: relative;
        top: 14px;
    }

    .mainSec.userprofile .p-breadcrumb {
        z-index: 9;
        position: fixed;
        width: -webkit-fill-available;
    }
}

.sing_up_main_Dialog.change .p-dialog-header {
    background-color: #124A99 !important;
    color: #fff !important;
    padding: 0 20px;
    height: 3rem;
    align-items: center;
}

.feild.feild-m .flex {
    width: 100% !important;
    margin: 0px !important;
    display: flex;
    gap: 20px !important;
}

.feild.feild-m .input input {
    width: 100%;
    font-size: 16px;
    margin: unset !important;
}

.feild.feild-m {
    padding: 20px !important;
}

@media screen and (min-width: 575px) {
    .md\:col-6 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 50%;
    }

    .md\:col-4 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 33.3333%;
    }

    .feild.feild-m .input input {
        width: 100%;
        font-size: 14px;
        margin: unset !important;
    }
}

@media screen and (max-width: 500px) {
    .feild.feild-m .flex {
        width: 100%;
        gap: 20px;
    }

    .feild.feild-m .input input {
        width: 100%;
        font-size: 13px;
        margin: unset !important;
    }

    .feild.feild-m .flex .input {
        width: 100%;
        margin: unset;
    }

}

@media screen and (max-width: 575px) {

    /* .col-12.md\:col-6.u1-a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-between;
        align-items: baseline;
        justify-content: space-between;
    }

    .col-12.md\:col-4.u1-a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-between;
        align-items: baseline;
        justify-content: space-between;
    } */

    small.errors-messages.text-red-500 {
        font-size: 12px;
    }

    .feild.feild-m .input input {
        width: 100%;
        font-size: 13px;
        margin: unset !important;
    }
}


.flex.btn {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    flex-wrap: wrap;
    align-content: flex-end;
}

.sing_up_main_Dialog.change {
    width: 24rem;
}

.menuItem .mainSec.userprofile {
    height: 85vh !important;
    overflow: auto;
}

.feild-m {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}

.feild.feild-m .flex {
    width: 100%;
    gap: 20px;
}

@media (min-width: 400px) and (max-width: 800px) {
    .sing_up_main_Dialog.change {
        width: 24rem !important;
    }
}

@media screen and (min-width: 575px) and (max-width: 850px) {
    .col-12.md\:col-4.u1-a {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;
    }
}

/* @media (min-width: 320px) and (max-width: 400px) {
    div#pr_id_682 {
        width: 17rem !important;
    }

    div#pr_id_1146 {
        width: 17rem !important;
    }

    small.errors-messages.text-red-500 {
        font-size: 12px;
    }
} */