.side_Bar {
    max-width: 300px;
    height: 100vh;
    background-color: #124A99;
    position: relative;
}

.menu span {
    gap: 18px;
    display: flex;
    background: none;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}

.menu.sub span {
    gap: 18px;
    display: flex;
    background: none;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}

.active.sub span {
    gap: 18px;
    display: flex;
    background: none;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}
.active1.sub span {
    gap: 18px;
    display: flex;
    background: none;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}

.active span {
    gap: 18px;
    display: flex;
    background: none;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}
.active1 span {
    gap: 18px;
    display: flex;
    background: none;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}

.product_Img {
    width: 230px;
    height: 87px;
    display: flex;
    justify-content: flex-start;
    position: fixed;
    top: 0px;
    padding: 0 20px;
}

.product_Img img {
    width: 150px;
    height: auto;
}

.menuBar {
    width: 230px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu_Items {
    width: 230px;
    color: #fff;
}

.mid_sec {
    width: 230px;
    height: 75vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
    top: 87px;
}

@media (max-height: 347px) {
    .mid_sec {
        width: 230px;
        height: 55vh !important;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
        top: 83px;
    }
}

@media (min-height: 347px) and (max-height: 358px) {
    .mid_sec {
        width: 230px;
        height: 64vh !important;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
        top: 83px;
    }
}

@media (min-height: 358px) and (max-height: 450px) {
    .mid_sec {
        width: 230px;
        height: 66vh !important;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
        top: 83px;
    }
}

.p-sidebar-content {
    overflow-y: hidden;
    flex-grow: 1;
}

@media (min-width: 801px) {
    .p-sidebar-mask.p-component-overlay.p-component-overlay-enter.p-sidebar-visible.p-sidebar-left {
        display: none;
    }
}

.menu_Items .height {
    width: -webkit-fill-available;
    height: 115px;
    background: none;
}

.menu_Items .menu {
    font-style: normal;
    width: 91%;
    font-size: 14px;
    min-height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background: none;
    cursor: pointer;
}

.menu_Items .menu:hover {
    background: #4871ab;
    border-radius: 8px;
}

.menu svg {
    font-size: 16px;
}

.active {
    font-style: normal;
    font-size: 14px;
    width: 91%;
    min-height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background: #4871ab;
    border-radius: 8px;
    cursor: pointer;
}

.active1 {
    font-style: normal;
    font-size: 14px;
    width: 91%;
    min-height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    /* background: #4871ab; */
    border-radius: 8px;
    cursor: pointer;
}

.bg {
    background: none !important;
}

.menu_Items .sub {
    width: 91%;
}

.footer_P {
    width: 230px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    font-size: 12px;
}

.footer_P img {
    width: 30px;
    height: 20px;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.tabView_menuBar {
    display: none;
    cursor: pointer;
}

.slider {
    width: auto !important;
    background-color: #124A99;
}

.slider .p-sidebar-content {
    padding: unset;
}

.slider .p-sidebar-header {
    display: none;
}

.Bar-icon {
    display: none;
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms none;
}

@media (max-width: 800px) {
    .menuBar {
        display: none;
    }

    .p-sidebar-content {
        display: block !important;
    }

    /* .Bar-icon {
        display: block !important;
    } */

    .active.abcd {
        font-style: normal;
        font-size: 13px;
        width: 91%;
        min-height: 35px;
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        padding: 0 15px;
        background: #4871ab !important;
        border-radius: 8px;
        cursor: pointer;
    }

    .active1.abcd {
        font-style: normal;
        font-size: 13px;
        width: 91%;
        min-height: 35px;
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        padding: 0 15px;
        /* background: #4871ab !important; */
        border-radius: 8px;
        cursor: pointer;
    }

    .Bar-icon {
        color: #124A99 !important;
        font-weight: 700 !important;
        height: 87px;
        background: #ffff;
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-bottom: 1px solid #dcdbdd !important;
    }

    .p-menu {
        border-radius: 0px;
    }

    .Bar-icon i {
        font-size: 20px !important;
    }

    .menu_Items2 .height {
        width: -webkit-fill-available;
        height: 115px;
        background: none;
    }

    .menu_Items2 .menu {
        font-style: normal;
        width: 91%;
        font-size: 13px;
        min-height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        background: none;
        cursor: pointer;
    }

    .menu_Items2 .menu:hover {
        background: #4871ab;
        border-radius: 8px;
    }

    .mid_sec {
        width: 230px;
        height: 75vh;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        position: relative;
        top: 75px;
    }

    .menu_Items .menu {
        font-style: normal;
        width: 91%;
        font-size: 13px;
        min-height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        background: none;
        cursor: pointer;
    }

    .menu_Items2 {
        width: 230px;
        /* height: 95% !important; */
        color: #fff;
    }

    .abcdef {
        width: 230px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .tabView_menuBar {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 74px;
        left: 2px;
        z-index: 9;
        background: #124A99;
        color: #ffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: lighter;
        border-radius: 5px;
    }
}