.feild .heading {
    height: 30px;
    align-items: center;
    color: #3f51b5;
    margin: 0px;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    gap: 10px;
}

.feild .flex {
    width: 100%;
    margin: unset;
    display: flex;
    gap: 20px;
}

.feild .flex .input {
    width: 100%;
}

.inputTextarea {
    width: 100%;
    min-height: 10vh;
}

.feild .input .label {
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: rgb(82, 87, 97);
    display: flex;
    gap: 5px;
}

.feild .input .label span {
    color: red;
}

.p-calendar .p-inputtext {
    border: 1px solid #ced4da;
}

.footer_sec_btn {
    width: 100%;
    height: 100px;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}

.footer_sec_btn button {
    color: #fff;
    border: #124A99;
    height: 35px;
    display: flex;
    gap: 10px;
}

.unique-label-form {
    margin: unset;
}

.gap {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.card_Sec {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sub_heading {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    gap: 20px;
}

.sub_heading button {
    border: #124A99;
    color: #fff;
    width: 30px;
    height: 30px;
    font-size: 12px;
    border-radius: 20px;
}

.sub_heading button .p-button-icon {
    color: #fff;
}

.card_Sec .p-card-body {
    padding: unset;
}

/* .search_btn {
    height: 40px;
    border: #124A99;
}

.search_btn .pi-search {
    color: #fff !important;
} */

.card_Sec2 .p-card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.p-dialog-top {
    align-items: center;
}

.padding {
    padding: 0 20px;
}

.feild .flex .input .calander .p-datepicker-trigger {
    border: unset;
}

.btn_sec .resetBtn .p-button-label {
    display: none;
}

.dropdown .p-icon {
    width: 12px;
}

.add_BTN {
    width: 18px !important;
    height: 18px !important;
}

.add_BTN .p-button-icon {
    font-size: 10px;
}

@media (max-width:575px) {
    .c .flex {
        flex-direction: column;
    }

    .mainSec {
        padding: 0 15px !important;
    }

    .feild .flex .input {
        width: 95%;
        margin: unset;
    }

    .feild .heading {
        position: unset;
        width: 90%;
        margin: auto;
        font-size: 14px;
    }

    .full_View {
        padding: unset;
    }
}