.maIn_Con {
  background: rgb(249, 248, 255);
  position: relative;
  top: 80px;
  display: flex;
  padding: 50px 0 100px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
}

.maIn_Con_ {
  width: 100vh;
  background: #ffff;
  position: relative;
  border-radius: 30px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3), -5px -5px 10px rgba(0, 0, 0, 0.1);
}

.conT_T {
  background: #093EA4;
  height: 100px;
  width: 100%;
}

.conT_B {
  background: #093EA4;
  height: 100px;
  width: 100%;
  opacity: 0.5;
}

.sadsad {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}


.ssaaaaaa_L {
  width: 100%;
  transform: rotate(30deg);
  transform-origin: center;
  position: relative;
  right: 120px;
}

.ssaaaaaa_R {
  width: 100%;
  transform: rotate(330deg);
  transform-origin: center;
  position: relative;
  left: 120px;
}

.contact-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contacter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-heading {
  text-align: center;
  font-size: 35px;
  color: #4e4e4e;
  font-weight: 600;
  margin: unset;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-background {
  background-color: rgba(252, 252, 252, 1);
  border: 1px solid rgba(156, 156, 156, 0.3);
  outline: none;
  border-radius: 10px;
}

.unique-form-inputs input {
  height: 60px;
}

.underliners {
  position: relative;
  text-decoration: none;
}

.underliners::after {
  content: "";
  position: absolute;
  bottom: 0px;
  top: 44px;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #124A99;
}

.underliners::before {
  content: "";
  position: absolute;
  bottom: 0px;
  top: 44px;
  right: 16px;
  width: 67px;
  height: 6px;
  background-color: #124A99;
}

.contact-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px
}

.contact-button {
  height: 35px;
  background-color: #124A99;
  padding: 0 15px;
  border: none;
  color: #ffffff;
  font-weight: 600;
  border-radius: 3px;
}

.p-float-label textarea~label,
.p-float-label .p-mention~label {
  top: 3rem;
}

.p-float-label label {
  left: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #9c9c9c;
  padding-left: 9px;
  line-height: 16px;
}

.p-float-label input:focus~label,
.p-float-label input:-webkit-autofill~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label,
.p-float-label .p-tooltip-target-wrapper~label {
  top: -0.75rem;
  font-size: 15px;
  padding-left: 0px;
}

.co_Form {
  margin: 1em 0;
}

.unique-form-inputs {
  width: 100%;
  padding: 10px 80px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.unique-form-inputs p {
  margin: unset !important;
}

@media (max-width: 1000px) {
  .maIn_Con_ {
    width: 90%;
  }
}

@media (max-width: 575px) {
  .unique-form-inputs {
    padding: 20px;
  }

  .unique-form-inputs input {
    height: 40px;
  }

  .unique-form-inputs textarea {
    height: 100px;
  }

  .p-float-label input:focus~label,
  .p-float-label input:-webkit-autofill~label,
  .p-float-label input.p-filled~label,
  .p-float-label textarea:focus~label,
  .p-float-label textarea.p-filled~label,
  .p-float-label .p-inputwrapper-focus~label,
  .p-float-label .p-inputwrapper-filled~label,
  .p-float-label .p-tooltip-target-wrapper~label {
    font-size: 13px;
  }

  .p-float-label label {
    font-size: 15px;
    font-weight: 600;
  }

  .underliners {
    position: relative;
    text-decoration: none;
  }

  .underliners::after {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 35px;
    right: 0;
    width: 6px;
    height: 5px;
    border-radius: 50%;
    background-color: #124A99;
  }

  .underliners::before {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 35px;
    right: 12px;
    width: 32px;
    height: 4px;
    background-color: #124A99;
  }

  .contact-heading {
    font-size: 25px;
  }
}


@media (max-width: 575px) {

  .ssaaaaaa_L {
    right: 85px;
  }

  .ssaaaaaa_R {
    left: 85px;
  }

}