.main-header-remaining {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    align-items: center;
}

.remaining-time {
    width: 88%;
    height: 35px;
    background: #ffff;
    display: flex;
    align-items: center;
    background-color: #a9b5cb;
    justify-content: center;
    color: white;
}

.remaining-times {
    height: 35px;
    display: flex;
    /* background-color: #124A99; */
    color: white;
    align-items: center;
}

.remaining-time h5 {
    font-size: 13px;
}

@media (max-width: 400px) {

    .remaining-time a {
        text-decoration: underline;
        margin-left: 5px;
        font-size: 9px !important;
        font-weight: bold;
        color: white;
    }

    .remaining-upgrade,
    .remaining-time h5 {
        font-size: 8px !important;
    }

    .remaining-time svg {
        font-size: 11px !important;
        cursor: pointer;
    }
}

@media (min-width:800px) and (max-width: 1070px) {
    .remaining-time {
        width: 78%;
        height: 35px;
        background: #ffff;
        display: flex;
        align-items: center;
        background-color: #a9b5cb;
        justify-content: center;
        color: white;
    }
}
@media (min-width:400px) and (max-width: 800px) {

    .remaining-upgrade,
    .remaining-time h5 {
        font-size: 11px !important;
    }
}

@media (max-width: 800px) {
    .remaining-time {
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #a9b5cb;
        color: white;
        /* margin-left: -60px; */
    }

    .remaining-time a {
        text-decoration: underline;
        margin-left: 5px;
        font-size: 11px;
        font-weight: bold;
        color: white;
    }

    .remaining-time svg {
        font-size: 17px;
        cursor: pointer;
    }
}