.chatbot {
    width: 340px;
    height: 415px;
    background-color: white;
}

.chat-are {
    height: 270px;
    background-color: white;
    overflow: auto;
    width: 100%;
}

.bot-message {
    background-color: rgba(241, 241, 241, 1);
    width: fit-content;
    padding: 0.5rem 0.4rem;
    border-radius: 6px;
    border-bottom-left-radius: 0%;
    color: rgba(146, 146, 146, 1);
    font-size: 12px;
}

.user-message {
    margin-left: auto;
    text-align: end;
    background-color: #124A99;
    width: fit-content;
    padding: 0.5rem 0.4rem;
    border-radius: 6px;
    border-top-right-radius: 0%;
    color: white;
    font-size: 12px;
}

.chatbot-input {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    justify-content: space-between;
}

.chatbot-input input {
    width: 100%;
    height: 35px;
    padding-left: 10px;
    border: rgba(217, 217, 217, 1) solid 0.5px;
    background-color: transparent;
    outline: none !important;
    border-radius: 1.5rem;
}

.chatbot-input input::placeholder {
    color: rgba(217, 217, 217, 1);
    font-size: 12px;
}

.chatbot-btn {
    height: 35px;
    width: 35px;
    border: none;
    background-color: #124A99;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 0.2rem;
}

.chatbot-btn .i-con {
    /* font-size: 16px; */
    color: white;
}

.chatbot-head {
    /* border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem; */
    background-color: #124A99;
    color: white;
    font-size: 17px;

}

.chatbot-1 .chatbot-head,
.chatbot-1 .chat-are,
.chatbot-1 .chatbot-input,
.chatbot-1 .chatbot-head-a1 {
    display: none;
}

.chatbot-1 .chatbot-head,
.chatbot-1 .suggested-questions {
    display: none !important;
}

.chatbot-close i {
    font-size: 14px;
    /* position: absolute;
 right: 1%;
 top: 10%; */
}

.w-max.chat-bot {
    bottom: 3%;
    right: -1.2%;
}

#chatbot-shadow {
    border-radius: 1.5rem;
}

.user-pb {
    padding: 0.3rem 0.5rem;
    background-color: #124A99;
    border-radius: 50%;
}

.user-pb-icon {
    color: white;
}

.user-pb1 {
    padding: 0.3rem 0.5rem;
    background-color: rgba(241, 241, 241, 1);
    border-radius: 50%;
}

.user-pb-icon1 {
    color: rgba(84, 84, 84, 1);
}

.headeset-btn {
    background-color: #124A99;
    color: white;
    border-radius: 5px;
    padding: 5px 10px 5px 5px;
}

.suggested-questions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.8rem;
    background-color: white;
}

.suggested-question-btn {
    border: none;
    font-size: 10px;
    border-radius: 25px;
    background-color: rgba(235, 235, 235, 1);
    color: rgba(146, 146, 146, 1);
}

.chatbot-close {
    /* position: absolute;
    right: 2% !important;
    top: 12%;
    margin: 0.3rem !important;
    cursor: pointer; */
    height: max-content;
}

@media (max-width: 420px) and (min-width:320px) {
    .w-max.chat-bot {
        bottom: 3%;
        right: -3.9% !important;
    }
}
@media (max-width: 500px) and (min-width:420px) {
    .w-max.chat-bot {
        bottom: 3%;
        right: -2.5% !important;
    }
}
@media (max-width: 750px) and (min-width:500px) {
    .w-max.chat-bot {
        bottom: 3%;
        right: -2.1% !important;
    }
}
@media (max-width: 750px) and (min-width:600px) {
    .w-max.chat-bot {
        bottom: 3%;
        right: -1.8% !important;
    }
}
@media (max-width: 900px) and (min-width:750px) {
    .w-max.chat-bot {
        bottom: 3%;
        right: -1.4% !important;
    }
}

@media (max-width: 575px) {
    .chatbot {
        width: 300px !important;
    }

    .chatbot-head {
        position: relative !important;
        justify-content: start !important;
    }
}