.err {
    height: 200px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.p-datatable-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

/* span.account.match {
    font-size: 11px;
    color: gray;
} */

span.red-star {
    color: red;
    font-size: 14px;
}

.green-background {
    background-color: rgb(247 175 0 / 17%) !important;
}

.blue-border {
    border: 4px solid rgb(247 175 0) !important;
}

.err i {
    font-size: xx-large;
    font-weight: bold;
    color: red;
}

.acc_Pending {
    width: -webkit-fill-available;
    height: auto;
    max-height: 40vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.acc_Pending_head {
    background: #124A99;
    color: #fff;
    min-height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 600;
    padding: 0 20px;
}

.acc_Pending_head i {
    font-weight: 600;
    font-size: 20px;
}

.info_D {
    width: auto;
    height: auto !important;
    min-height: 50px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 20px;
}

.info_D .leable {
    color: #5d6170;
    font-weight: 600;
}

.info_D .value {
    color: rgb(143, 143, 143);
    font-weight: 500;
}

.div {
    border-bottom: 1px solid rgb(143, 143, 143);
}

.jj {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
}

@media (min-width:320px) and (max-width:343px) {
    span.account.match {
        font-size: 9px;
        color: gray;
    }

    span.red-star {
        color: red;
        font-size: 11px;
    }
}

@media (min-width:343px) and (max-width:372px) {
    span.account.match {
        font-size: 10px;
        color: gray;
    }

    span.red-star {
        color: red;
        font-size: 11px;
    }
}

@media (min-width:372px) and (max-width:472px) {
    span.account.match {
        font-size: 11px;
        color: gray;
    }

    span.red-star {
        color: red;
        font-size: 11px;
    }
}

@media (min-width:472px) {
    span.account.match {
        font-size: 14px;
        color: gray;
    }
}

@media (max-width: 575px) {

    .feild .heading,
    .formgrid {
        margin: unset !important;
    }

    .feild .heading button {
        padding: unset;
        font-size: 14px !important;
    }
}