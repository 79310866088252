.card-beneficiary {
    width: 100%;
    margin-left: 0%;
    margin-right: 2.5%;
    /* background-color: red; */
}

.beneficiary-txt {
    padding-left: 4% !important;

}
.react-tel-input.error-border .flag-dropdown,
.react-tel-input.error-border .form-control {
    border-color: #ff4032 !important;
}

.error-border {
    border-color: #ff4032 !important; 
}

.trans-search .p-button-icon {
    color: white !important;
}

.p-dialog-content {
    overflow: hidden;
}

.new-btn .p-button-icon {
    color: #091F41 !important;
}

.export-btn .p-button-icon {
    color: #091F41 !important;
}

.new-btn .p-button-icon:hover {
    color: #fff !important;
}

.export-btn .p-button-icon:hover {
    color: #fff !important;
}

/* .new-btn .p-button:hover{
      color: #fff !important;
      background-color: red !important;
    } */
.new-btn {
    border-radius: 50%;
    padding: 22px !important;
    background-color: #fff !important;
    box-shadow: 0 4px 5px #091F41;
}

.export-btn {
    border-radius: 50%;
    padding: 21.5px !important;
    background-color: #fff !important;
    box-shadow: 0 4px 5px #091F41;
}

.pl-4 {
    margin-left: 50px;
    /* width: 94%; */
}

.check {
    height: 500px;
}

#trans-edit {
    padding: 0 !important;
    background-color: transparent !important;
}

/* .border-round-sm {
    border-radius:0.2rem !important;
} */


/* .text-xs {
  font-size: 15px !important;
} */
.beneficiary-datatable.p-datatable>.p-datatable-wrapper {
    height: 34vh !important;
}
.unique-cons .unique-lio{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
@media (max-height: 1000px) and (min-height:600px) { 
    .menuItem .mainSec.tran {
        width: -webkit-fill-available;
        height: 77vh !important;
        position: fixed;
        overflow: auto;
        top: 141px;
    }
}
@media (max-width: 500px) { 

    /* .mainSec .feild {
        position: relative;
        top: 15px;
    } */
    .unique-mobile-fiel.field .flex {
        gap: 10px !important;
    }
}