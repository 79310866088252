button#b-btn\ thresold {
   background: transparent;
   color: black;
   border: none;
   flex-direction: row;
   flex-wrap: wrap;
   align-content: center;
   gap: 5px;
   display: flex !important;
}

button.p-button.p-component.footer_Sub_Btn.alertCriteria.p-button-icon-only {
   width: -moz-fit-content;
   width: 2.357rem !important;
   height: 0px !important;
   padding: 0 !important;
   font-size: 14px;
   background: #124A99;
   border: 1px solid #124A99;
}

button.p-inputnumber-button.p-inputnumber-button-up.p-button.p-button-icon-only.p-component {
   width: -moz-fit-content !important;
   width: 2.357rem !important;
   height: 0px !important;
   padding: 0px !important;
   font-size: 14px;
   background: #124A99;
   border: 1px solid #124A99;
}
button.p-inputnumber-button.p-inputnumber-button-down.p-button.p-button-icon-only.p-component {
   width: -moz-fit-content !important;
   width: 2.357rem !important;
   height: 0px !important;
   padding: 0px !important;
   font-size: 14px;
   background: #124A99;
   border: 1px solid #124A99;
}

.feild .flex-thresolds {
   width: 100%;
   margin: auto;
   display: flex;
   padding: 15px;
   gap: 70px;
   /* align-items: end; */
}

.input.flex.thresold-input-country {
   width: 70% !important;
   margin-right: 40px;
}


.feild-2-thresold {
   display: flex;
   justify-content: center;
   flex-direction: column;
   padding-left: 20px;
   gap: 20px;
   padding-right: 20px;
   padding-top: 20px;
}

.sing_up_main_Dialog.transaction.alerts {
   width: 40rem !important;
}

.transaction.alerts .p-dialog-header-icons {
   position: relative;
   top: 0px;
}

.sing_up_main_Dialog.transaction.alerts .p-dialog-header {
   background-color: #124A99 !important;
   color: #fff !important;
   padding: 0 20px;
   height: 4rem;
   align-items: center;
}

.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
   border-top: 0 none;
   background: none !important;
   color: black;
   height: 3.5rem;
   border: 0px;
}

.sing_up_main_Dialog.transaction.alerts .line-height-4 {
   font-size: 14px !important;
}

.mainSec.thresold {
   display: flex !important;
   flex-direction: column !important;
   gap: 0px !important;
   padding: 0 25px;
}

.accordionTab {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: stretch;
   gap: 30px;
   width: 96%;
   position: relative;
   left: 2%;
}

.input-thresold {
   width: 30%;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   align-content: center;
   justify-content: space-between;
   align-items: center;
   box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
}

.flex-thresold {
   display: flex;
   gap: 58px;
}

.p-knob.p-component svg {
   height: 70px;
   width: 70px;
}

.flex_footer_btns.flex_footer_btn {
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 0px;
   position: relative;
   width: 100%;
   top: -15px;
}

.feild.thresold {
   width: 95%;
   position: relative;
   left: 30px;
}

.know_flex_footer_btn {
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   align-content: center;
   width: 100%;
   position: relative;
   left: 0px;
   padding-bottom: 35px !important;
   padding-right: 50px !important;
   padding-top: 5px !important;
}

button.p-button.p-component.know_footer_Sub_Btn {
   background: #124A99;
   border: 1px solid #124A99;
   border-radius: 6px;
   display: flex !important;
   gap: 10px;
}

.thresold.flex-wrap.column-gap-8.row-gap-8 {
   width: 100%;
   display: flex !important;
   flex-direction: row;
   padding-left: 50px;
   padding-right: 56px;
   padding-top: 20px;
   row-gap: 2rem !important;
   align-content: center;
   column-gap: 1rem !important;
   justify-content: space-between;
}



.w-16rem.align-items-center.justify-content-center {
   width: 19rem !important;
   box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
}

.sanction_country.flex_footer_btn {
   height: 50px;
   display: flex;
   justify-content: flex-end;
   margin: 0px !important;
   width: 100%;
   flex-wrap: wrap;
   flex-direction: column;
   align-items: center;
   align-content: flex-end;
}

.mainSec .aaa {
   height: 80vh;
   overflow: auto;
   scrollbar-width: none;
}

.mainSec .feild .help {
   height: 40px !important;
   border-radius: 6px;
}

.accordionTab .p-button .p-button-icon {
   color: #fff;
   background: transparent;
}

.accordionTab .p-accordion-header-link {
   background: transparent;
}

.accordionTab .p-accordion-toggle-icon {
   display: none;
}

.accordionTab .p-accordion-header-text {
   width: 100%;
}

.accordionTab .p-accordion-header-link {
   box-shadow: none !important;
   color: black;
   border-radius: 8px !important;
   background: white !important;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important
}

.mt-drop-icon,
.open-class-mt-drop-icon {
   display: flex;
   align-items: center;
   font-weight: inherit;
   color: gray;
   justify-content: space-between;
   flex-direction: row;
   position: relative;
   left: 0px;
   width: 100%;
}

.alertCriteria {
   width: 20px !important;
   height: 20px;
   background: transparent !important;
   box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) !important;
   border: none !important;
}

.alertCriteria .p-button-icon {
   color: rgba(145, 145, 145, 1);
}

.box {
   display: flex;
   width: 100%;
   height: 9rem;
   /* min-width: max-content !important; */
   justify-content: center;
   box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
   padding: 10px;
}

.box .r_ {
   width: 50%;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   top: 10px;
}

.box .l_ {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: flex-start;
   position: relative;
}

.box .l_ .p_ {
   font-weight: bold;
   font-size: 18px;
}

.box .l_ .p_,
.box .l_ .h_ {
   display: flex;
   justify-content: center;
}

.accordionTab .p-accordion-content {
   display: flex;
   flex-direction: column;
   border: 0px;
   gap: 20px;
   width: 100%;
   /* width: 93%;
   position: relative;
   left: 42px; */
   padding: 20px !important;
}

.accordionTab .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.accordionTab .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
   background: none !important;
   color: black;
   height: 3.5rem;
   border: 0px;
}

.dropIconB {
   background: transparent;
   border: none;
   height: 30px;
   display: flex;
   align-items: center;
   color: gray;
}

.dropIcon span {
   color: #124A99;
}

.dropIconW span {
   color: black !important;
}

.dropIcon:hover {
   color: black;
}

.chart-container .p-card-body,
.chart-container .p-card-content {
   padding: unset !important;
}

.mainSec .p-card .p-card-body {
   border-bottom: unset !important;
}

.input .p-inputnumber-button-group .p-button {
   background: #124A99;
   border: 1px solid #124A99;
}

.flex_footer_btn .footer_Sub_Btn .p-button-icon {
   color: #c1c1c1;
   font-size: 8px;
   padding-top: 1px !important;
}

.p-accordion .p-accordion-tab {
   border-radius: 6px !important;
   box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
}

.p-accordion .p-accordion-header .p-accordion-header-link {
   padding: 0 3rem !important;
   padding-left: 2rem !important;
   box-shadow: none !important;
   border: 0px !important;
}

.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
   border-top: 0 none;
   background: none !important;
   color: black;
   height: 3.5rem;
   border: 0px;
}

.mt-drop-text {
   color: rgba(145, 145, 145, 1) !important;
}

.open-class-mt-drop-text {
   color: rgba(42, 55, 89, 1) !important;
   /* font-weight: bold; */
   font-size: 16px !important;
}

button.p-button.p-component.footer_Sub_Btn.alertCriteria.p-button-icon-only {
   width: 17px !important;
   height: 17px !important;
}

@media (min-width:1745px) and (max-width:1820px) {

   .thresold.flex-wrap.column-gap-8.row-gap-8 {
      width: 100%;
      display: flex !important;
      flex-direction: row;
      gap: 110px;
      padding-left: 50px;
      padding-right: 56px;
      padding-top: 20px;
      row-gap: 2rem !important;
      align-content: center;
      column-gap: 10rem !important;
      justify-content: flex-start;
   }
}

@media (min-width:1820px) {

   .thresold.flex-wrap.column-gap-8.row-gap-8 {
      width: 100%;
      display: flex !important;
      flex-direction: row;
      gap: 110px;
      padding-left: 50px;
      padding-right: 56px;
      padding-top: 20px;
      row-gap: 2rem !important;
      align-content: center;
      column-gap: 2rem !important;
      justify-content: flex-start;
   }
}

@media (max-width:1700px) {
   .dssdfds {
      height: 60vh;
      overflow: auto;
   }
}

@media (max-width:800px) {
   .mt-drop-text {
      font-size: 13px;
      color: rgba(145, 145, 145, 1) !important;
   }
}

@media (max-width:1391px) {
   .mt-drop-text {
      font-size: 14px;
      color: rgba(145, 145, 145, 1) !important;
   }
}

@media (max-width:1150px) {

   .mt-drop-icon,
   .open-class-mt-drop-icon {
      display: flex;
      align-items: center;
      font-weight: inherit;
      color: gray;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      width: 100%;
      left: 0px;
   }
}

@media (max-width:1030px) {
   .feild-2 .flex {
      flex-direction: column;
   }

   .feild-2-3 .flex {
      flex-direction: column;
   }

   .input.flex.thresold-input-country {
      width: 100% !important;
      margin-right: 0px;
   }
}

@media (min-width:320px) and (max-width:340px) {
   .p-accordion .p-accordion-header .p-accordion-header-link {
      padding: 0 2rem !important;
      padding-left: 2rem !important;
      box-shadow: none !important;
      border: 0px !important;
   }
}

@media (min-width:1284px) and (max-width:1391px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }
}

@media (min-width:1200px) and (max-width:1284px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }
}

@media (min-width:1122px) and (max-width:1200px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }
}

@media (min-width:1096px) and (max-width:1122px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }

}

@media (min-width:1122px) and (max-width:1124px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      /* width: 96%; */
      width: 100%;
      /* position: relative;
      left: 15px; */
      padding: 0px !important;
   }

}

@media (min-width:1150px) and (max-width:1150px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      /* width: 96%;
      position: relative;
      left: 15px; */
      padding: 0px !important;
   }

   .mt-drop-icon,
   .open-class-mt-drop-icon {
      display: flex;
      align-items: center;
      font-weight: inherit;
      color: gray;
      justify-content: space-between;
      flex-direction: row;
      /* position: relative;
      left: 35px;
      width: 96%; */
      width: 100%;
   }
}

@media (min-width:1124px) and (max-width:1150px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;
   }

   .mt-drop-icon,
   .open-class-mt-drop-icon {
      display: flex;
      align-items: center;
      font-weight: inherit;
      color: gray;
      justify-content: space-between;
      flex-direction: row;
      /* position: relative;
      left: 5px;
      width: 99%; */
      width: 100%;
   }
}

@media (min-width:1061px) and (max-width:1096px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:1027px) and (max-width:1061px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:1000px) and (max-width:1027px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:957px) and (max-width:1000px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:922px) and (max-width:957px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:900px) and (max-width:922px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:800px) and (max-width:900px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }

   .open-class-mt-drop-text {
      color: rgba(42, 55, 89, 1) !important;
      /* font-weight: bold; */
      font-size: 14px !important;
   }

   .mt-drop-icon,
   .open-class-mt-drop-icon {
      display: flex;
      align-items: center;
      font-weight: inherit;
      color: gray;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      width: 100%;
      /* width: 92%;
      left: 34px; */
   }
}

@media (min-width:751px) and (max-width:800px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .open-class-mt-drop-text {
      color: rgba(42, 55, 89, 1) !important;
      /* font-weight: bold; */
      font-size: 14px !important;
   }

   .mt-drop-icon,
   .open-class-mt-drop-icon {
      display: flex;
      align-items: center;
      font-weight: inherit;
      color: gray;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      width: 100%;
      /* width: 92%;
      left: 34px; */
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:713px) and (max-width:751px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   span.p-dropdown-label.p-inputtext {
      font-size: 14px;
   }

   input#stacked-buttons {
      font-size: 14px;
   }

   .open-class-mt-drop-text {
      color: rgba(42, 55, 89, 1) !important;
      /* font-weight: bold; */
      font-size: 14px !important;
   }

   .mt-drop-icon,
   .open-class-mt-drop-icon {
      display: flex;
      align-items: center;
      font-weight: inherit;
      color: gray;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      width: 100%;
      /* width: 92%;
      left: 34px; */
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:700px) and (max-width:713px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 46% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   span.p-dropdown-label.p-inputtext {
      font-size: 14px;
   }

   input#stacked-buttons {
      font-size: 14px;
   }

   .mt-drop-icon,
   .open-class-mt-drop-icon {
      display: flex;
      align-items: center;
      font-weight: inherit;
      color: gray;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      width: 100%;
      /* width: 92%; */
      /* left: 34px; */
   }

   .open-class-mt-drop-text {
      color: rgba(42, 55, 89, 1) !important;
      /* font-weight: bold; */
      font-size: 14px !important;
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      padding: 20px !important;

   }
}

@media (min-width:320px) and (max-width:850px) {
   .open-class-mt-drop-text {
      color: rgba(42, 55, 89, 1) !important;
      /* font-weight: bold; */
      font-size: 14px !important;
   }
}

@media (min-width:320px) and (max-width:500px) {
   .thresold.flex-wrap.column-gap-8.row-gap-8 {
      width: 100%;
      display: flex !important;
      flex-direction: row;
      padding: unset !important;
      row-gap: 2rem !important;
      align-content: center;
      column-gap: 1rem !important;
      justify-content: center;
   }

   .sing_up_main_Dialog.transaction.alerts {
      width: 20rem !important;
   }

   span.p-dropdown-label.p-inputtext {
      font-size: 12px;
   }

   input#stacked-buttons {
      font-size: 12px;
   }

   .open-class-mt-drop-text {
      color: rgba(42, 55, 89, 1) !important;
      /* font-weight: bold; */
      font-size: 14px !important;
   }

   .w-16rem.align-items-center.justify-content-center {
      width: 100% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }
}

@media (min-width:500px) and (max-width:700px) {
   .w-16rem.align-items-center.justify-content-center {
      width: 70% !important;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
   }

   .sing_up_main_Dialog.transaction.alerts {
      width: 30rem !important;
   }

   .mt-dialog-content {
      font-size: 12px;
   }

   .mt-dialog-content {
      font-size: 13px;
   }

   span.p-dropdown-label.p-inputtext {
      font-size: 14px;
   }

   input#stacked-buttons {
      font-size: 14px;
   }
}

.thresold_designation {
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   align-content: center;
   width: 100%;
   position: relative;
   left: 0px;
   padding-bottom: 18px !important;
   padding-right: 0px !important;
   padding-top: 30px !important;
}

@media (max-width: 500px) {
   .feild-2 .flex .input {
      width: 100%;
      margin: unset;
   }

   .feild-2-3 .flex .input {
      width: 95%;
      margin: unset;
   }
}

@media (min-width:320px) and (max-width:700px) {
   .know_flex_footer_btn {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      width: 100%;
      position: relative;
      left: 0px;
      padding-bottom: 35px !important;
      justify-content: center !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 0px !important;
   }

   .thresold.flex-wrap.column-gap-8.row-gap-8 {
      width: 100%;
      display: flex !important;
      flex-direction: row;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 50px;
      padding-top: 20px;
      row-gap: 2rem !important;
      align-content: center;
      column-gap: 1rem !important;
      justify-content: center;
   }

   .mt-drop-icon,
   .open-class-mt-drop-icon {
      display: flex;
      align-items: center;
      font-weight: inherit;
      color: gray;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      width: 100%;
      /* width: 98%;
      left: 7px; */
   }

   .accordionTab .p-accordion-content {
      display: flex;
      flex-direction: column;
      border: 0px;
      gap: 20px;
      width: 100%;
      position: relative;
      /* left: 0px; */
      padding-left: 18px !important;
      padding-right: 18px !important;
   }
}

@media (max-height:458px) {
   .mainSec .aaa {
      height: 56vh !important;
      overflow: auto;
      /* scrollbar-width: none; */
   }
}
@media (max-height:600px) and (min-height:458px) {
   .mainSec .aaa {
      height: 67vh !important;
      overflow: auto;
      /* scrollbar-width: none; */
   }
}