.about_Sec {
    width: 100%;
    height: auto;
    background: #F9F8FF;
    position: relative;
    top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding: 90px 20px;
}

.third_C_SEC {
    display: flex;
    justify-content: center;
    gap: 100px;
}

.third_C__N_ {
    width: 90%;
    color: #4E4E4E;
    font-size: 20px;
    line-height: 30px;
}

.ri_GHT img {
    width: 100%;
}

@media (max-width: 1000px) {
    .third_C_SEC {
        flex-direction: column;
    }

    .third_C_L,
    .third_C_R,
    .third_C_SEC {
        gap: 15px;
    }
}

@media (max-width: 800px) {
    .third_C_SEC {
        flex-direction: column;
        gap: 10px;
    }

    .fLE_X {
        flex-direction: column;
    }

    .ri_GHT {
        display: flex;
        justify-content: center;
    }

    .ri_GHT img {
        width: 60%;
    }
}