.fooTER_Sec {
  width: 100%;
  background-color: #093EA4;
  color: #ffffff;
  position: relative;
  top: 80px;
}

.rrR {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px;
}

.p_BY {
  display: flex;
  align-items: end;
  font-weight: 600;
  font-size: 16px;
  flex-direction: column;
}

.bY {
  display: flex;
  gap: 25px;
  align-items: center;
}


.foo_SM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  font-weight: 600;
  font-size: 16px;
}

.foo_SM_I {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.foo_SM_I svg {
  font-size: 35px;
  color: #ffffff;
}

.foo_C {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 25px;
}

.foo_C_1 {
  font-weight: 700;
  font-size: 20px;
}

.foo_C_2 {
  font-weight: 500;
  font-size: 30px;
}

.foo_C_3 {
  font-size: 20px;
}

.teaMS {
  width: 100%;
  font-weight: normal;
}

.brEE {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.p_BY_C {
  display: flex;
  cursor: pointer;
  width: calc(100% - 180px);
  align-items: center;
  justify-content: center;


  position: relative;
  left: 50px;
}

@media (max-width: 1100px) {

  .rrR {
    flex-direction: column;
    height: auto;
    padding: 25px 0;
    flex-wrap: unset;
    gap: 20px;
  }

  .p_BY_C {
    width: unset;
    flex-wrap: wrap;
  }

  .fooTER_Sec {
    gap: unset;
  }

}

@media (max-width: 600px) {
  .foo_C_3 {
    text-align: center;
  }
}

@media (max-width: 575px) {

  .p_BY {
    flex-wrap: wrap;
    gap: unset;
  }

  .p_BY,
  .foo_SM {
    font-size: 14px;
  }

  .foo_C_3 {
    font-size: 16px;
  }

  .foo_C_2 {
    font-size: 25px;
  }

  .foo_C_1 {
    font-size: 18px;
  }

  .teaMS p {
    width: max-content;
  }

  .foo_Dia .p-dialog-content {
    padding: unset !important;
  }

}

@media (max-width: 540px) {
  .brEE {
    height: 90px;
    flex-direction: column;
    justify-content: end;
    padding: 5px 0px;
  }
}