.c_position {
    width: 175px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.card-Adhoc .p-breadcrumb {
    height: 40px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 9;
    width: -webkit-fill-available;
    padding: 0 15px;
}

.feild .flex .input .calander .p-datepicker-trigger {
    border: unset;
}

.btn_sec {
    width: 90%;
    height: 80px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9;
}

.btn_sec .p-button:hover {
    border: 1px solid rgb(42, 55, 89);
}

.btn_sec .resetBtn .p-button-label {
    display: none;
}

.back_g {
    width: 80%;
    margin: auto;
    background: white;
    border: 2px solid #c1c1c1;
    border-radius: 15px;
    position: relative;
    top: 25px;
}

.unique-label {
    height: 65px;
    margin: unset;
    padding: 0 20px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.unique-label .input {
    width: 80%;
    padding: 0 10px;
    height: 40px;
    margin: auto;
}

.unique-label span {
    width: 80%;
    height: auto;
    margin: auto;
    position: relative;
    top: 5px;
    font-size: 14px;
}

.chec-boxs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    min-height: 40px;
    height: auto;
    padding: 10px;
}

.chec-boxs .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.card .adhoc-input-single .feild-Sec {
    width: 100%;
    height: auto;
    display: flex;
    gap: 20px;
    padding: 0 20px;
    justify-content: space-between;
}

/* .p-dropdown-panel .p-dropdown-header {
    padding: 5px 15px !important;
}

.p-dropdown-filter-container input {
    padding: unset !important;
    height: 25px !important;
}

.p-dropdown-items-wrapper {
    max-height: 100px !important;
}

.p-dropdown-panel .p-dropdown-items {
    padding: unset !important;
}

.p-dropdown-item {
    padding: 0 20px;
    min-height: 30px;
    display: flex;
    align-items: center;
} */

.btn_sec .p-button-icon {
    color: #fff !important;
}

#cam .label {
    font-weight: 600 !important;
}

#cam {
    border-top: solid 1px #c1c1c1 !important;
}

.main_base {
    width: 80%;
    margin: auto;
    border: 2px solid #c1c1c1;
    border-radius: 15px;
    height: auto;
    overflow: auto;
    gap: 20px;
}

.both_main_base {
    height: auto !important;
}

.dropdown .p-icon {
    width: 12px;
}

@media (max-width:1090px) {
    .main_base {
        height: 70vh;
    }

    .wra {
        flex-wrap: wrap;
    }
}

@media (max-width:800px) {

    .unique-label .input,
    .unique-label span {
        width: 100%;
    }

    .back_g {
        width: 100%;
    }
}

@media (max-width:600px) {

    .adhoc-input-single div {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .adhoc-input-single div .unique-label {
        width: -webkit-fill-available;
    }

    .chec-boxs {
        flex-direction: column;
        width: fit-content;
        align-items: flex-start;
    }

    .chec-boxs .flex {
        display: flex !important;
        flex-direction: initial;
    }

    .align {
        display: flex;
        align-items: center;
    }
}

@media (min-height:556px) {
    .menuItem .mainSec.max-height-adhoc {
        width: -webkit-fill-available;
        height: 77vh;
        position: fixed;
        top: 122px;
        scrollbar-width: thin;
        overflow: auto;
    }
}

@media (min-height:455px) and (max-height:556px){
    .menuItem .mainSec.max-height-adhoc {
        width: -webkit-fill-available;
        height: 69vh;
        position: fixed;
        top: 121px;
        scrollbar-width: thin;
        overflow: auto;
    }
}

@media (max-height:454px) and (min-height:387px) {
    .menuItem .mainSec.max-height-adhoc {
        width: -webkit-fill-available;
        height: 69vh;
        position: fixed;
        scrollbar-width: thin;
        top: 121px;
        overflow: auto;
    }
}
@media (max-height:387px) and (min-height:315px) {
    .menuItem .mainSec.max-height-adhoc {
        width: -webkit-fill-available;
        height: 61vh;
        scrollbar-width: thin;
        position: fixed;
        top: 121px;
        overflow: auto;
    }
}
@media (max-height:315px) {
    .menuItem .mainSec.max-height-adhoc {
        width: -webkit-fill-available;
        height: 49vh;
        position: fixed;
        top: 121px;
        scrollbar-width: thin;
        overflow: auto;
    }
}

@media (max-width:575px) {

    .m_view {
        position: relative;
        top: -60px;
    }

    .a_AS .card {
        top: 50px;
    }

    .c_position label {
        font-size: 14px;
    }

    .feild .flex .input {
        width: 100%;
    }
}