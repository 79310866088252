.back-label {
  display: none !important;
}

.container1 {
  width: 100%;
  position: fixed;
  transition: 0.5s ease;
  border: 1px solid #fff;
}

.log-one {
  width: 1000px;
  height: 600px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.log-a-one {
  width: 500px;
  background-color: #124A99;
  position: relative !important;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.log-b-one {
  width: 500px;
  background-color: white;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.logomainlog {
  display: flex;
  width: 100px;
  height: 40px;
  position: absolute !important;
  left: 4% !important;
  top: 3% !important;
  text-align: center !important;
}

.logomainlog img {
  width: 100px;
  height: 40px;
}

.centerimage {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 16%;
  left: 5%;
}

.centerimage img {
  width: 450px;
  height: 420px;
}

.client-card1 {
  font-size: 26px;
  color: #124A99;
  font-weight: 600;
}

.wlcm-txt {
  color: rgba(189, 189, 189, 1);
}

#eye-icon {
  position: absolute;
  top: 57%;
  right: 4%;
}

.invalid #eye-icon {
  top: 42% !important;
  z-index: 9999 !important;
}

.log-for {
  font-size: 14px !important;
  font-weight: 500;
  color: #124A99 !important;
}

label {
  font-family: system-ui;
  font-weight: 550;
  font-size: 15px;
  line-height: 18px;
  color: rgb(82, 87, 97);
}

.login-here1 {
  color: #767676 !important;
}

.login-form-f-acaia #log-bn {
  border: none;
  background-color: #124A99;
  font-weight: 600;
}

.newuser-text-border {
  height: 1px !important;
  background-color: rgb(211, 215, 225);
}

.otp-txt {
  font-size: 16px !important;
  line-height: 28px;
  color: rgba(189, 189, 189, 1);
}

.tash-otp {
  color: transparent !important;
}

#log-head-acia {
  height: 3.6rem !important;
}

.spin-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invalid-input,
.invalid-input:enabled:hover {
  border-color: #ff4032 !important;
}

.log-b-one .errors-messages {
  position: absolute;
  top: 102%;
  left: 0%;
}

/* #sub-heading {
  font-size: 14px;
} */

.password_input {
  font-size: 12px;
  font-weight: bold;
  border: 1px solid rgb(211, 215, 225);
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.password_input input {
  border: none;
}

.password_input div {
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back_BTN {
  justify-content: flex-start !important;
  color: #124A99;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: flex-start;
}

.back_BTN i {
  font-size: 30px;
}

.logo_f {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
  top: 0px;
}

@media (max-width:575px) {
  label {
    font-family: system-ui;
    font-weight: 550;
    font-size: 13px;
    line-height: 18px;
    color: rgb(82, 87, 97);
  }
}