.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem #124A9929;
    outline: 0 none;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #e9ecef;
    color: #124A99;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #124A99;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #124A99;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: #124A99;
}

#branches .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
    text-align: center !important;
}

#country-area .p-datatable>.p-datatable-wrapper {
    height: 34.5vh !important;
}

.p-dialog .p-dialog-content {
    overflow: auto;
}

.country-conatent {
    width: 100% !important;
}

.Toastify__toast-theme--light {
    margin: unset;
}

.Toastify__toast-container--top-right {
    top: 89px;
} 

@media (max-height: 600px) and (min-height:400px) {
    .menuItem .mainSec.cou {
        width: -webkit-fill-available;
        height: 74vh;
        position: fixed;
        top: 121px;
        overflow: auto;
    }
}

@media (max-height: 400px) {
    .menuItem .mainSec.cou {
        width: -webkit-fill-available;
        height: 62vh;
        position: fixed;
        top: 121px;
        overflow: auto;
    }
}

@media (min-width: 1492px) and (max-width:1700px) {
    .menuItem .mainSec.cou {
        width: -webkit-fill-available;
        height: 80vh;
        position: fixed;
        top: 121px;
        overflow: auto;
    }
}

@media (max-width: 500px) {
    .cou .card {
        position: relative;
        top: 50px;
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 90px;
        width: 325px;
        right: 0;
        left: unset;
    }
} 