.third-con {
  display: flex;
  max-width: 1400px;
  padding: 25px 0;
  margin: 0 auto;
  position: relative;
  top: 80px;
}

.third-main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.third-heading {
  font-size: 45px;
  font-weight: 600;
  color: #4e4e4e;
}

.third-para {
  font-size: 22px;
  font-weight: 600;
  line-height: 45px;
  color: #858585;
  text-align: justify;
  display: flex;
  justify-content: center;
  width: 100%;
}

.acaia_image {
  width: 100%;
  position: relative;
  /* bottom: 4%; */
  justify-content: center;
}

.underline-waves {
  position: relative;
  text-decoration: none;
}

.underline-waves::after {
  content: "";
  position: absolute;
  bottom: 0px;
  top: 64px;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #124A99;
}

.underline-waves::before {
  content: "";
  position: absolute;
  bottom: 0px;
  top: 65px;
  right: 16px;
  width: 67px;
  height: 6px;
  background-color: #124A99;
}

.third_C {
  display: flex;
  width: 85%;
  gap: 15px;
}

.third_C_ {
  display: flex;
  align-items: center;
}

.third_C_I {
  color: #093EA4;
  font-size: 36px;
}

.third_C__N {
  width: 90%;
  color: #858585;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.third_C_L,
.third_C_R {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1399px) and (min-width: 1201px) {
  .third-con {
    width: 85%;
    justify-content: center;
  }

  .third-heading {
    font-size: 42px;
    font-weight: 600;
    color: #4e4e4e;
  }

  .third-para {
    font-size: 20px;
    font-weight: 600;
    line-height: 45px;
    color: #858585;
    justify-content: center;
    text-align: justify;
    width: 95%;
  }

  .acaia_image {
    position: relative;
    /* bottom: 4%; */
    width: 1000px;
    height: 700px;
  }

  .underline-waves {
    position: relative;
    text-decoration: none;
  }

  .underline-waves::after {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 59px;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #124A99;
  }

  .underline-waves::before {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 60px;
    right: 16px;
    width: 67px;
    height: 6px;
    background-color: #124A99;
  }
}

@media (max-width: 1200px) and (min-width: 1032px) {
  .third-con {
    width: 85%;
    justify-content: center;
  }

  .third-heading {
    font-size: 40px;
    font-weight: 500;
    color: #4e4e4e;
  }

  .third-para {
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
    color: #858585;
    justify-content: center;
    text-align: justify;
    width: 95%;
  }

  .acaia_image {
    position: relative;
    /* bottom: 4%; */
    width: 850px;
    height: 680px;
  }

  .underline-waves {
    position: relative;
    text-decoration: none;
  }

  .underline-waves::after {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 54px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #124A99;
  }

  .underline-waves::before {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 55px;
    right: 20px;
    width: 60px;
    height: 6px;
    background-color: #124A99;
  }
}

@media (max-width: 1031px) and (min-width: 769px) {
  .third-con {
    width: 85%;
    justify-content: center;
  }

  .third-heading {
    font-size: 36px;
    font-weight: 500;
    color: #4e4e4e;
  }

  .third-para {
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    color: #858585;
    justify-content: center;
    text-align: justify;
    width: 90%;
  }

  .acaia_image {
    position: relative;
    /* bottom: 4%; */
    width: 650px;
    height: 500px;
  }

  .underline-waves {
    position: relative;
    text-decoration: none;
  }

  .underline-waves::after {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 49px;
    right: 4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #124A99;
  }

  .underline-waves::before {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 50px;
    right: 20px;
    width: 55px;
    height: 5px;
    background-color: #124A99;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .third-con {
    width: 85%;
    justify-content: center;
  }

  .third-heading {
    font-size: 38px;
    font-weight: 500;
    color: #4e4e4e;
  }

  .third-para {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    color: #858585;
    justify-content: center;
    text-align: justify;
    width: 95%;
  }

  .acaia_image {
    position: relative;
    /* bottom: 4%; */
    width: 450px;
    height: 380px;
  }

  .underline-waves {
    position: relative;
    text-decoration: none;
  }

  .underline-waves::after {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 49px;
    right: 11px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #124A99;
  }

  .underline-waves::before {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 50px;
    right: 25px;
    width: 50px;
    height: 4px;
    background-color: #124A99;
  }
}

@media (max-width: 1200px) {
  .third_C__N {
    font-size: 18px;
  }
}

@media (max-width: 1031px) {
  .third_C__N {
    font-size: 16px;
  }
}

@media (max-width: 800px) {

  .third_C {
    flex-wrap: wrap;
  }

  .third_C_L,
  .third_C,
  .third_C_R {
    gap: 10px;
  }
}

@media (max-width: 575px) {
  .third_C__N {
    font-size: 14px;
  }

  .third-con {
    width: 100%;
    justify-content: center;
    padding: 25px 0;
  }

  .third-heading {
    font-size: 30px;
    font-weight: 600;
    color: #4e4e4e;
  }

  .third-para {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #858585;
    /* justify-content: center; */
    text-align: justify;
    width: 90%;
  }

  .acaia_image {
    position: relative;
    /* bottom: 4%; */
    width: 280px;
    height: 230px;
  }

  \  .underline-waves {
    position: relative;
    text-decoration: none;
  }

  .underline-waves::after {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 40px;
    right: 9px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #124A99;
  }

  .underline-waves::before {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 40px;
    right: 18px;
    width: 40px;
    height: 4px;
    background-color: #124A99;
  }
}


@media (max-width :420px) {

  .third-para,
  .third_C__N {
    font-size: 12px;
  }

  .third_C_L,
  .third_C,
  .third_C_R {
    gap: 5px;
  }
}