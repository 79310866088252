.spin-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    background: rgba(218, 216, 216, 0.395);
    font-size: '48px';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    z-index: '9';
}

.spinner {
    position: absolute;
    height: 90px;
    width: 90px;
    border: 10px solid transparent;
    border-top: 12px solid #091F41;
    border-right: 12px solid #68a1c1;
    border-bottom: 12px solid #091F41;
    border-left: 12px solid #68a1c1;
    top: 50%;
    left: 50%;
    margin: -30px;
    border-radius: 50%;
    animation: spin 0.9s linear infinite;

    /* &:before, &:after{
        content:'';
        position: absolute;
        border: 10px solid transparent;
        border-radius: 50%;
      }
      
      &:before{
        border-top-color: #f76c09;
        top: -12px;
        left: -12px;
        right: -12px;
        bottom: -12px;
        animation: spin 3s linear infinite;
      }
      
      &:after{
        border-top-color: #f708bb;
        top: 6px;
        left: 6px;
        right: 6px;
        bottom: 6px;  
        animation: spin 4s linear infinite;
      } */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.gJsnXL {
    background-color: #68a1c1;
}

.iOpdRq {
    border-color: #68a1c1;
}

.spinner-new {
    /* position: absolute; */
    /* height: 120px;
    width: 120px; */
    /* border: 10px solid transparent;
    border-top: 16px solid blue;
    border-right: 16px solid rgb(221, 6, 138);
    border-bottom: 16px solid rgb(183, 255, 0);
    border-left: 16px solid pink; */
    top: 50%;
    left: 50%;
    margin: -30px;
    /* border-radius: 50%; */
    /* animation: spin 1s linear infinite; */
}