.main {
    width: -webkit-fill-available;
    height: 100vh;
    background: #acaba7;
}

.acaia-body {
    width: 100%;
    height: 95vh;
    display: flex;
}

.acaia-body .menuItem {
    position: fixed;
    left: 230px;
    top: 87px;
    width: 100%;
}

@media (max-width: 800px) {
    .acaia-body .menuItem {
        left: 0;
    }
}

/* common changes */

.menuItem .mainSec {
    width: -webkit-fill-available;
    height: 100vh;
    position: fixed;
    /* top: 121px; */
    /* top: 86px; */
    overflow: auto;
}

.mainSec {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 0 25px;
}

.feild {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
}

.mainSec .condent_sec {
    width: 100%;
    height: 70vh;
    overflow: auto;
}

.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled).p-focus {
    border-color: #ced4da;
    box-shadow: unset;
}

.p-dropdown .p-dropdown-label,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 14px;
}

.p-datatable {
    box-shadow: rgb(216, 216, 216) 2px 2px 5px, rgb(211, 205, 205) -2px -2px 5px;
}

.p-datatable-wrapper {
    scrollbar-width: none;
}

.p-column-title {
    white-space: nowrap;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    padding: 0 20px;
    height: 50px;
    font-size: 16px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td,
.p-datatable .p-datatable-tbody>tr>td {
    height: 40px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td:last-child {
    height: 43px;
}

.p-inputtext:enabled:focus,
.p-inputtext:enabled:hover,
.p-multiselect:not(.p-disabled):hover {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: rgb(211, 215, 225);
}

.feild .input input {
    font-size: 14px;
    width: 100%;
}

.p-dialog .p-dialog-footer button {
    padding: unset;
    font-size: 14px;
    width: 70px;
    height: 35px;
    background: #124A99;
    border: 1px solid #124A99;
}

.p-dropdown,
#p-calender .p-button {
    height: 40px;
}

#p-calender .p-button {
    height: 40px;
}

.p-dropdown .p-dropdown-label {
    display: flex;
    align-items: center;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-link:focus {
    box-shadow: unset !important;
}

.p-dialog .p-dialog-footer button {
    padding: unset;
}

.p-button,
.p-button .p-button:enabled:hover,
.p-dialog .p-dialog-footer button,
.p-button.p-button-icon-only {
    width: fit-content;
    height: 35px;
    padding: 0 15px;
    font-size: 14px;
    background: #124A99;
    border: 1px solid #124A99;
}

.p-button:enabled:hover {
    background: #124A99;
    border-color: #124A99;
}

.p-button.p-button-info.p-button-text:enabled:hover {
    background: transparent;
    border-color: transparent;
}

#p-calender .p-button {
    background-color: #124A99;
    color: #fff;
    padding: 0 12px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 16px;
}

.mainSec .p-breadcrumb {
    padding: unset;
    height: 50px;
    display: flex;
    align-items: center;
}

.h_ing {
    min-height: 35px;
    display: flex;
    align-items: center;
    background: rgb(248 249 250);
    padding: 0 10px;
    font-size: 16px;
    color: #5d6170;
    font-weight: 600;
}

.pi-info-circle:hover,
.pi-info-circle {
    width: auto;
}

.pi-info-circle:before {
    color: rgb(42, 55, 89);
    font-weight: bold;
    width: fit-content;
}

.pi-info-circle .p-button-label {
    display: none;
}

.carD {
    height: 75vh;
    border: 1px solid #dcdbdd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}

.p-card .p-card-title {
    height: 50px;
    overflow: auto;
    font-size: 18px;
    font-weight: 700;
    /* margin: 10px 0px; */

    display: flex;
    align-items: center;
    background: #124A99;
    padding: 0 20px;
    color: #fff;
    margin: 30px 0;
    border-radius: 8px;
    display: none;
}

.p-card .p-card-content {
    padding: unset;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.back_BT_N {
    border: 1px solid #124A99;
    margin: unset;
    width: 70px;
    background: transparent !important;
    color: #124A99 !important;
    padding: unset;
    margin: unset !important;
}

.p-dialog-content {
    overflow: auto;
}

.R_es .p-dialog-content,
.R_es .p-dialog-footer {
    padding: unset !important;
    margin: unset;
}

.adh .p-datatable-wrapper {
    max-height: 65vh !important;
}

.w-7rem {
    width: unset !important;
}

@media (max-width: 1200px) {

    .carD {
        height: 70vh !important;
    }
}

@media (max-width: 1000px) {

    .h_ing {
        font-size: 16px;
    }

    .info_D .leable,
    .info_D .value {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    .mainSec .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
        font-size: 14px;
    }
}

@media (max-width: 575px) {

    .p-breadcrumb-list,
    .menuItem .mainSec {
        gap: 15px;
    }

    .mainSec .p-breadcrumb .p-breadcrumb-list li:not(:first-child):not(:last-child) {
        display: none;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th,
    .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
        font-size: 14px;
    }

    /* .adh .p-datatable-wrapper {
        height: 65vh !important;
    } */

    .h_ing {
        font-size: 14px;
    }

    .p-dropdown .p-dropdown-label,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
    .p-highlight,
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        font-size: 12px;
    }

    .feild .flex .input {
        width: 100%;
    }

    .feild .flex {
        gap: 10px;
    }

    .feild .input .label {
        font-size: 10px;
    }

    .feild .input input,
    .flag-dropdown,
    .p-dropdown {
        font-size: 12px;
        height: 35px !important;
    }

    #p-calender .p-button,
    .see_Pass i,
    .p-dialog .p-dialog-footer button,
    .p-button:enabled:hover .p-button.p-button-icon-only {
        width: fit-content;
        font-size: 12px;
        height: 35px !important;
    }

    /* .p-dropdown, */

}