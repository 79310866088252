.feild.feild-history .abcd .unique-datatable-mob .flex {
    width: 100%;
    height: 30px;
    gap: 20px;
}

.feild.feild-history input.p-inputtext.p-component {
    font-size: 16px;
}

.feild.feild-history span.p-dropdown-label.p-inputtext {
    font-size: 16px;
}

.feild.feild-history .flex.align-items-center.justify-content-end.m-2 {
    gap: 0px;
}

.unique-datatable-mob .p-datatable>.p-datatable-wrapper {
    overflow: auto;
}

.mainSec.unique-margin-removes .condent_sec {
    width: 100%;
    height: 70vh;
    overflow: auto;
}

.mainSec.unique-margin-removes .condent_sec::-webkit-scrollbar {
    display: none;
}

.unique-datatable-mob .p-datatable>.p-datatable-wrapper::-webkit-scrollbar {
    display: none;
}

.unique-footer-sec-btn .p-button {
    color: #ffffff !important;
    background: #124A99 !important;
    border: 1px solid #124A99 !important;
    font-size: 1rem;
    border-radius: 6px;
    gap: 8px !important;
}

button#b-btns-im {
    z-index: 1;
    border: none !important;
    background-color: transparent !important;
    color: #124A99 !important;
}

button#b-btns-ex {
    z-index: 1;
    border: none !important;
    background-color: transparent !important;
    color: #124A99 !important;
}

@media (max-height: 225px) {
    .mainSec.unique-margin-removes .condent_sec {
        width: 100%;
        height: 39vh;
        overflow: auto;
    }
}

@media (min-height: 225px) and (max-height: 350px) {
    .mainSec.unique-margin-removes .condent_sec {
        width: 100%;
        height: 47vh;
        overflow: auto;
    }
}

@media (min-height: 351px) and (max-height: 450px) {
    .mainSec.unique-margin-removes .condent_sec {
        width: 100%;
        height: 66vh;
        overflow: auto;
    }
}

@media (min-height: 450px) {
    .mainSec.unique-margin-removes .condent_sec {
        width: 100%;
        height: 77vh;
        overflow: auto;
    }
}

@media (max-width: 575px) {
    .unique-footer-sec-btn.flex.align-items-center.justify-content-end {
        display: flex;
        flex-direction: row;
    }

    .mainSec.unique-margin-removes {
        display: flex !important;
        flex-direction: column !important;
        gap: 25px !important;
        padding: 0 25px;
    }

    .feild.feild-history input.p-inputtext.p-component {
        font-size: 14px;
    }

    .feild.feild-history span.p-dropdown-label.p-inputtext {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .feild.feild-history .flex .input {
        width: 100%;
        margin: unset;
    }

    .mainSec.unique-margin-removes {
        display: flex !important;
        flex-direction: column !important;
        gap: 25px !important;
        padding: 0 25px;
    }

}

label {
    font-family: system-ui;
    font-weight: unset !important;
    font-size: 15px;
    line-height: 18px;
    color: rgb(82, 87, 97);
}

.unique-footer-sec-btn .p-button .p-button-icon {
    color: white;
    font-weight: 500;
}

.unique-footer-sec-btn .p-button {
    color: #ffffff;
    background: #124A99;
    border: 1px solid #124A99;
    /* padding: 0.5rem 0.5rem !important; */
    font-size: 1rem;
    gap: 8px !important;
}

.reset-btns.p-button {
    gap: 0px !important;
}

.unique-search-exp-imp.flex {
    width: 100% !important;
}

.unique-datatable-mob tr:nth-child(even) {
    background-color: white !important;
}

.unique-datatable-mob {
    position: relative;
}

.unique-datatable-mob .p-datatable-thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.unique-datatable-mob .p-datatable>.p-datatable-wrapper {
    height: auto !important;
    max-height: 45vh !important;
}

/* .menuItem .mainSec.unique-margin-removes {
    height: auto !important;
} */

.unique-row-expansion.tyuyt {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    flex-direction: row;
    height: 11rem;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-around;
}

.p-dropdown .p-dropdown-label {
    display: flex;
    /* align-items: flex-start !important; */
}

.unique-row-expansion {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    flex-direction: row;
    /* height: 14rem; */
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-around;
}

label.col-3.w-12rem.lkjhg {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    /* top: 30px; */
    width: 100% !important;
}

.flex.justify-content-center.align-items-center.abcd-reason {
    width: 100% !important;
    position: relative;
    left: 29.5%;
}

.col-6.avdf {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 30rem;
}

@media (max-width: 575px) {
    .flex.justify-content-center.align-items-center {
        flex-direction: row;
    }

    .feild.feild-history .flex.align-items-center.justify-content-end.m-2 {
        gap: 0px;
        flex-direction: row;
    }
}

@media (max-width: 500px) {
    .unique-datatable-mob .p-datatable>.p-datatable-wrapper {
        height: auto !important;
        max-height: 30vh !important;
    }

    .unique-row-expansion-mob label {
        font-size: 13px !important;
    }

    .unique-row-expansion-mob .flex {
        display: flex;
        flex-direction: row !important;
    }

    .mob-view-hide {
        display: none !important;
    }

    .mob-unique-his-fields {
        display: flex !important;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 0px !important;
    }

    .unique-footer-sec-btn {
        padding-right: 0px !important;
    }

    .unique-search-exp-imp .p-inputtext {
        width: 150px !important;
    }

    .unique-search-exp-imp .flex {
        flex-direction: row !important;
    }

    .mob-label-hide .p-button .p-button-label {
        display: none !important;
    }

    .mob-font .p-button {
        font-size: 14px !important;
        /* padding: 0.4rem 0.4rem !important; */
    }

    .mob-font .p-button .p-button-icon {
        font-size: 14px !important;
    }

    .unique-datatable-mob .p-datatable .p-datatable-tbody>tr>td {
        font-size: 14px;
        padding: 0.5rem 0.8rem
    }

    .unique-datatable-mob .p-datatable .p-datatable-thead>tr>th {
        padding: 0.5rem 0.8rem
    }

    .unique-datatable-mob .p-icon {
        width: 14px !important;
    }

    .unique-datatable-mob .p-checkbox .p-checkbox-box {
        width: 17px !important;
        height: 17px !important;
    }

    /* .Acount-profile-expander-txt .flex {
        flex-direction: row !important;
    } */
}

@media (max-width: 620px) {
    .unique-search-exp-imp .p-inputtext {
        width: 150px !important;
    }
}

@media (min-width:800px) and (max-width: 1000px) {
    .unique-search-exp-imp .p-inputtext {
        width: 150px !important;
    }
}

@media (min-width:500px) and (max-width: 575px) {
    .unique-row-expansion-mob .flex {
        display: flex;
        flex-direction: row !important;
    }

    label.col-2.font-semibold {
        font-family: system-ui;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: rgb(82, 87, 97);
    }
}

@media (max-height: 400px) {
    .menuItem .mainSec.unique-margin-removes {
        width: -webkit-fill-available;
        height: 58vh;
        position: fixed;
        top: 121px;
        overflow: auto;
    }
}

@media (max-height: 600px) and (min-height:400px) {
    .menuItem .mainSec.unique-margin-removes {
        width: -webkit-fill-available;
        height: 68vh;
        position: fixed;
        top: 121px;
        overflow: auto;
    }
}

@media (max-width: 1300px) {
    /* .unique-row-expansion {
        display: none !important;
    } */

    .unique-row-expansion-mob {
        font-size: 13px !important;
        width: 45rem;
        overflow: auto;
    }

}