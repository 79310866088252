.mainSec {
    height: auto;
}

/* .mainSec .feild-Sec .p-dropdown {
    border: 1px solid #dcdbdd !important;
    border-radius: 6px !important;
} */

.footer_sec_btn .p-button-icon {
    color: #fff !important;
}

@media (max-width: 575px) {

    .height_m_view {
        height: 65vh !important;
    }
}

@media (max-height: 450px) {

    .mainSec.main-scroll-height {
        height: 74vh !important;
        width: -webkit-fill-available;
        position: fixed;
        scrollbar-width: thin;
        overflow: auto;
    }
}
@media (min-height: 450px) and (max-height:600px) {

    .mainSec.main-scroll-height {
        height: 74vh !important;
        width: -webkit-fill-available;
        position: fixed;
        scrollbar-width: thin;
        overflow: auto;
    }
}