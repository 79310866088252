.header-maintain {
  width: 100%;
  padding: 0 25px;
  position: fixed;
  z-index: 99;
}

.header_F {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-but {
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  background-color: #124A99;
  border-radius: 3px;
  padding: 0 15px;
}

button.header-but.hamburger-icon {
  height: 35px;
  font-size: 20px;
  font-weight: 600;
  color: black;
  border: none;
  background-color: #f9f8ff;
  border-radius: 3px;
  padding: 0 15px;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  top: 71px;
  width: 100%;
  background-color: #f9f8ff;
  gap: 10px;
  right: 0px;
  padding: 22px;
}

@media (min-width: 435px) and (max-width: 600px) {
  .mobile-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    align-items: stretch;
    position: absolute;
    top: 71px;
    width: 100%;
    background-color: #ffffff;
    gap: 10px;
    right: 0px;
    padding: 22px;
    transition: top 0.5s ease;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 320px) and (max-width: 435px) {
  .mobile-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    align-items: stretch;
    position: absolute;
    top: 71px;
    width: 100%;
    background-color: #ffffff;
    gap: 10px;
    right: 0px;
    padding: 22px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 600px) {
  .mobile-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    align-items: stretch;
    position: absolute;
    top: 71px;
    width: 100%;
    background-color: #ffffff;
    gap: 10px;
    right: 0px;
    padding: 22px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 600px) {

  .header-maintain {
    padding: 0 10px;
  }
}

@media (max-width: 750px) {

  .main-hed {
    display: none
  }
}

.main-hed-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  gap: 35px;
  justify-content: space-between;
  align-items: center;
}

.header-buts {
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  background-color: #124A99;
  border-radius: 3px;
  padding: 0 15px;
}

.link-button-header-maintain {
  display: contents;
}



a.btn-align-center {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  font-weight: 600;
  color: #124A99;
}