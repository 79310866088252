.mainSec {
    height: auto;
}

/* .mainSec .feild-Sec .p-dropdown {
    border: 1px solid #dcdbdd !important;
    border-radius: 6px !important;
} */

.feild .flex .input .calander .p-datepicker-trigger {
    border: unset;
}

.footer_sec_btn .resetBtn .p-button-label {
    display: none;
}

.p-error{
    font-size: 12px;
}