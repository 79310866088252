#branches .p-datatable .p-datatable-thead>tr>th {
  text-align: center !important;
  align-items: center;
}

.footer_sec_btn_dialuge {
  width: 100%;
  height: 40px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
}

ul.country-list {
  max-height: 80px !important;
}

button#b-btn {
  margin: 0 !important;
  border: none;
}

#edit-btn .p-button {
  background-color: transparent !important;
  border: none !important;
  color: #091F41 !important;

  font-size: 16px !important;
  font-weight: bolder;
  padding: 0;
}

.p-button:focus {
  outline: none !important;
}

#edit-btn.p-button:hover {
  background-color: transparent !important;
  background-image: none !important;
  color: #091F41 !important;

  font-size: 16px !important;
  font-weight: bolder !important;
  padding: 0 !important;

}

.export-drop {
  padding: 0 !important;
}

#branches.p-button:focus {
  outline: none !important;
}

.center {
  text-align: center !important;
}

#branches .p-datatable .p-column-header-content {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#branches .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #fff;
}

#branches .p-datatable .p-sortable-column.p-highlight {
  color: #fff;
}

.new-btn {
  background-color: #091F41 !important;
  background-image: none !important;
  width: 7rem;
  height: 2rem;
  margin-right: 2rem;
  color: #fff;

}

.new-btn .p-button-icon {
  color: #fff;
}

.export-btn {
  background-color: #091F41 !important;
  background-image: none !important;
  width: 7rem;
  height: 2rem;
  margin-right: 2rem;
  z-index: 1;
}

.export-btn .p-button-icon {
  color: #fff;
}

#country-area .p-datatable>.p-datatable-wrapper {
  height: 33.5vh !important;
}

#country-area .p-datatable>.p-datatable-wrapper {
  height: 63vh !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  border-bottom: 1px solid #e9ecef !important;
  border-width: 0 0 1px 0;
}

.breanch-dialog .p-dialog-header {
  background-color: #124A99 !important;

}

.branch-expand-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
}

.branch-expand-details-b {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  width: 40%;
}

.branch-expand-details-b1 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.font-semibold {
  width: 30%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.branch-expand-details-b3 {
  width: 60%;
}

.branch-expand-details-b2 {
  width: 10%;
}

.branch-expand-details-a3 {
  width: 60%;
}

.branch-expand-details-a2 {
  width: 10%;
}

.branch-expand-details-a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  width: 40%;
}

.branch-expand-details-a1 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.breanch-dialog .p-dialog-header .p-dialog-title {
  color: white !important;
  padding-left: 0.5rem !important;
}

.breanch-dialog .p-dialog-header-close-icon {
  color: white !important;
}

.card-btn .pi {
  color: white !important;
  font-size: small !important;
  font-weight: 600 !important;
}

.tab-head-btn .p-button .pi {
  color: #124A99 !important;
  font-weight: bolder !important;
}

@media (max-width: 500px) {
  .unique-data-btn {
    gap: 10px !important;
  }

  .unique-fie-mob {
    gap: 20px !important;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .card.card-Adhoc.w-full.scrole-height {
    position: relative;
    top: -15px;
    margin: 0px !important;
  }
}

@media (max-height: 600px) and (min-height: 220px) {
  .menuItem .mainSec.branch-scroll {
    width: -webkit-fill-available;
    height: 70vh;
    position: fixed;
    top: 121px;
    overflow: auto;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .tab-head-btn .p-button {
    margin-right: 0 !important;
  }

  .tab-head-btn {
    flex-wrap: nowrap !important;
  }

  .tab-head-btns {
    flex-wrap: nowrap !important;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    font-size: 13px;
  }

  .p-datatable .p-datatable-thead>tr>th {
    font-size: 14px;

  }
}

.user-Activity-customer-list .p-datatable>.p-datatable-wrapper {
  height: 32vh !important;
  position: relative;
}

.unique-dat .p-datatable>.p-datatable-wrapper {
  overflow: unset !important;
}

.unique-phone .form-control {
  padding-left: 60px !important;
}

.unique-phone .selected-flag {
  border: 1px solid #CACACA !important;
}

.form-control {
  height: 40px !important;
}

.country.preferred.active {
  display: none;
}