.flexs {
    display: flex;
    padding: 5px 10px;
    gap: 20px;
}

.flexs .input input {
    width: 100%;
}

.flex .input {
    width: 100%;
}
/* 
.mainSec .p-card .p-card-body {
    border-bottom: 1px solid #dcdbdd !important;
}

.mainSec .p-card .p-card-body .p-card-title {
    margin: unset;
}

.mainSec .feild .flex .acc {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
} */

.center {
    min-height: 50px;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: unset !important;
}

.center :hover,
.act {
    border-bottom: 2px solid #3f51b5;
    font-weight: bold;
    cursor: pointer;
}

.flex .input .flexs .input .label {
    height: 30px;
}