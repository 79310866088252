.header-main {
  width: 100%;
  height: 87px;
  background: #ffff;
  display: flex;
  align-items: center;
}

.notification-web h2 {
  font-size: 20px;
}

.dropdown-contentr.shadow-2 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 12px;
  padding: 8px;
  max-height: 250px;
  padding-top: 6px;
  overflow: auto;
}

.dropdown-item {
  /* border-bottom: 1px solid #8080808a; */
  padding: 5px;
  cursor: pointer;
}

.p-dialog .p-dialog-header {
  background-color: #124A99 !important;
  color: white !important;
  border-bottom: 1px solid gray !important;
}

.p-dialog-header-close-icon {
  color: white !important;
  font-weight: bold;
}

.sing_up_main_Dialog.search-header {
  width: 24rem !important;
}

.p-sidebar .p-sidebar-header {
  padding: 1rem;
  display: none;
}

@media (max-width: 800px) {
  .sing_up_main_Dialog.search-header {
    width: 24rem !important;
  }
}

@media (max-width: 750px) {
  .bell-notification {
    font-size: 19px !important;
  }
}

@media (max-width: 400px) {
  .sing_up_main_Dialog.search-header {
    width: 17rem !important;
  }
}

.p-dropdown-panel .p-dropdown-items-wrapper {
  max-height: 200px !important;
}

.header-title {
  width: -webkit-fill-available;
  height: 87px;
  color: #124A99;
  font-weight: bold;
  font-size: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcdbdd !important;
}

.header-main .p-menu {
  height: 87px;
  width: 100% !important;
  padding: 0 20px;
  border-bottom: 1px solid #dcdbdd !important;
}

.header-main .p-menu ul {
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
}

.header-main .p-menu ul li .p-menuitem {
  margin: unset !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 16px !important;
  color: #124A99 !important;
  font-weight: bold;
}

.feild .input.mnmnmn input {
  margin: unset !important;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid rgb(211, 215, 225);
  /* border-left: none; */
  border-radius: 0 !important;
}

.pi-info-circle:before {
  color: rgb(73 80 87) !important;
  font-weight: normal !important;
  width: -moz-fit-content;
  width: fit-content;
}

.pi-fw {
  width: 1rem !important;
  text-align: center;
}

.see_Pass i {
  border: 0px !important;
  border-left: 1px solid rgb(211, 215, 225) !important;
  border-top: 1px solid rgb(211, 215, 225) !important;
  height: 40px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 !important;
  border-bottom: 1px solid rgb(211, 215, 225) !important;
}


.custom-badge {
  font-size: 9px;
  position: relative;
  top: -35px;
  right: -7px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:800px) {
  .notification-web h2 {
    font-size: 17px;
  }

  .dropdown-item {
    font-size: 14px;
  }

  .header-title {
    display: none;
    font-size: 18px;
  }

  .header-main .p-menu ul {
    gap: 15px;
  }

  /* div#pr_id_2 {
    width: 75% !important;
  } */

  .header-main .p-avatar {
    display: none;
  }

  .header-main .p-menuitem {
    min-width: auto !important;
  }

  .relative .p-dropdown-label {
    padding: unset;
  }
}

@media (max-width:350px) {

  .header-title {
    font-size: 16px;
    padding: 0 10px 0px 20px;
    display: none;
  }

  .h-max .header-co.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 15px !important;
  }

  /* div#pr_id_2 {
    width: 90% !important;
  } */

  .header-main .p-menu {
    padding: 0px 20px 0px 10px;
  }
}