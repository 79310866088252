.unique-text-change .p-dropdown-label.p-inputtext {
    width: 100px !important;
    font-size: 14px;
    color: #495057;
}

.unique-multiselect-font {
    overflow: inherit !important;
}

.unique-multiselect-font .p-multiselect .p-multiselect-label {
    font-size: 14px;
    color: #495057;
}

.unique-text-area .p-inputtextarea.p-inputtext {
    font-size: 14px;
    color: #495057;
}

.unique-text-change .p-dropdown {
    height: 40px !important;
}

.unique-close-icon .p-dialog-header-close-icon {
    display: none;
}

.feild .input .label {
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: rgb(82, 87, 97);
    display: flex;
    gap: 5px;
}

.unique-adjust-gap .flex {
    margin-bottom: 10px !important
}

@media (max-width:575px) {
    .unique-text-change .p-dropdown-label.p-inputtext {
        font-size: 12px;
        color: #495057;
    }

    .unique-text-change .p-dropdown {
        height: 35px !important;
    }

    .unique-multiselect-font .p-multiselect .p-multiselect-label {
        font-size: 12px;
        color: #495057;
    }

    .unique-text-area .p-inputtextarea.p-inputtext {
        font-size: 12px;
    }
}