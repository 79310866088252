.otp-input-field {
    border: none;
    padding: unset;
    outline: none;
    width: 40px !important;
    border: 1px solid #cccccc;
    height: 40px;
    font-size: 25px;
}

.user-mail-otp {
    color: rgb(0, 114, 180);
}

.sing_up_main_Dialog {
    width: 30rem !important;
}

.sing_up_main_Dialog .otp-container {
    justify-content: center !important;
    gap: 20px;
}

.back {
    flex-direction: column !important;
    /* gap: 30px; */
}

.r_side .autoH {
    top: 0 !important;
}

.sing_up_main_Dialog .p-dialog-header-close-icon {
    color: #fff !important;
    background: none;
}

.aling_DD {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    gap: 10px;
}

@media (max-width: 575px) {

    .r_side .back_BTn {
        align-items: flex-start !important;
    }

    .feild .forget {
        justify-content: center;
    }

    .forgetMargin {
        justify-content: center;
    }

    .forget .in {
        margin: unset !important;
    }

    .feild .heigt {
        height: auto;
    }

    .otp-input-field {
        width: 30px !important;
        height: 35px;
    }

    .sing_up_main_Dialog .otp-container {
        gap: 10px;
    }

}