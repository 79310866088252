._C {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.label_HEIGTH {
    align-items: end;
    height: 35px;
}

@media (max-width:800px) {
    .R_es {
        width: 80% !important;
    }
}

@media (max-width:575px) {

    ._C {
        justify-content: flex-end;
    }

    .flex_D {
        flex-direction: column;
        gap: 5px !important;
    }

    .flex_D span {
        width: 100% !important;
    }
}