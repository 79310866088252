.unique-label-drops .p-float-label .unique-form-labels {
  left: 0rem;
}

.unique-label-drops input {
  font-size: 16px !important;
}

.unique-label-drops {
  font-size: 16px !important;
}

.col-12.md\:col-12.lg\:col-12.Personal {
  background: #ffffff;
  margin: 9px;
  width: 1200px;
  box-shadow: 2px 2px 5px #d8d8d8, -2px -2px 5px #d3cdcd;
}

.col-12.md\:col-12.lg\:col-12.Countries {
  background: #ffffff;
  width: 1200px;
  margin: 9px;
  box-shadow: 2px 2px 5px #d8d8d8, -2px -2px 5px #d3cdcd;
}

.col-12.md\:col-12.lg\:col-12.Roles {
  background: #ffffff;
  width: 1200px;
  margin: 9px;
  box-shadow: 2px 2px 5px #d8d8d8, -2px -2px 5px #d3cdcd;
}

.col-12.md\:col-12.lg\:col-12.Alias {
  background: #ffffff;
  width: 1200px;
  margin: 9px;
  box-shadow: 2px 2px 5px #d8d8d8, -2px -2px 5px #d3cdcd;
}

.col-12.md\:col-12.lg\:col-12.Dates {
  background: #ffffff;
  width: 1200px;
  margin: 9px;
  box-shadow: 2px 2px 5px #d8d8d8, -2px -2px 5px #d3cdcd;
}

.col-12.md\:col-12.lg\:col-12.Address {
  background: #ffffff;
  width: 1200px;
  margin: 9px;
  box-shadow: 2px 2px 5px #d8d8d8, -2px -2px 5px #d3cdcd;
}

.col-12.md\:col-12.lg\:col-12.Ids {
  background: #ffffff;
  width: 1200px;
  margin: 9px;
  box-shadow: 2px 2px 5px #d8d8d8, -2px -2px 5px #d3cdcd;
}

.col-12.md\:col-12.lg\:col-12.Notes {
  background: #ffffff;
  width: 1200px;
  margin: 9px;
  box-shadow: 2px 2px 5px #d8d8d8, -2px -2px 5px #d3cdcd;
}

.unique-form {
  row-gap: 0px !important;
}

.label-form,
.exact-name-h2 {
  font-size: 16px !important;
}

.p-Inputgroup span:last-child {
  margin-left: auto !important;
}

.p-breadcrumb .p-breadcrumb-link:focus {
  outline: none !important;
  border: none !important;
}

.p-toast {
  top: 9% !important;
}

.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus {
  box-shadow: none !important;
  background-color: transparent !important;
}

.update-bn-beneficiary .pi {
  font-size: 28px !important;
  font-weight: bold !important;
}

.p-button {
  outline: none !important;
  box-shadow: none !important;
}

.font-orange-500 {
  /* color: #FF9800; */
  background-color: #fef2c2;
}

.p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
  color: #124A99;
}

.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text,
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
  border-color: transparent !important;
  color: #3f51b5;
  font-weight: 650;
}

.p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator .p-icon {
  color: #3f51b5;
  font-weight: bold;
  height: 0.9rem !important;
}

input.p-inputtext.p-component.input-adhoc.w-full.input-adhoc.w-full {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden !important;
  background: transparent;
  background-color: rgba(0, 0, 0, 0);
  font-size: 0.9rem;
  outline: none;
  border-radius: 0px !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #124A99 !important;
  background: #124A99 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #124A99;
  background: #124A99;
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #2f3c5e !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #2f3c5e !important;
  box-shadow: 0 0 0 0.2rem #fafafa !important
}

.input-adhoc.w-full:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: transparent !important;
}

.p-panel-title {
  font-size: 1.5rem;
}

.p-panel-content {
  background-color: #f0f0f0;
}

::placeholder {
  font-size: small;
  font-weight: 700;
}

.p-panel .p-panel-header {
  background-color: #68A1C1;
  color: #fff;
}

.client-card .card {
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.adhoc-header {
  background-color: #68A1C1;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#advanced-btn .p-button:hover {
  background: #091F41 !important;
  background-color: #091F41 !important;
  background-image: none !important;
}

.Advancedsearch-btn {
  width: 4rem;
  height: 4rem;
  top: 30%;
}

.btn-advanced {
  position: relative;
}

.btn-advanced .pi {
  position: absolute;
  top: 16%;
  font-size: 20px;
}

.btn-arrow-right {
  background-color: transparent;
  border: none;
  color: #124A99;

}

.btn-arrow-right .pi {
  font-weight: bold;
  font-size: 25px;
}

#ad-drop {
  transition: 0.5s !important;
}


.p-divider.p-divider-horizontal {
  padding: 0.1rem;
}


.p-divider.p-divider-horizontal:before {
  border-top: transparent;
}

.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
  font-size: 15px;
}

.error-message {
  color: red;
  font-size: 12px;
}


.p-float-label label {
  color: #939393;
  left: 0.5rem;
  transition-duration: .2s;
}

.invalid-form-label {
  margin-top: -1rem !important;
}

.invalid-label-ads #ad-fm {
  margin-top: -2.0% !important;
}




.input-adhoc {
  /* padding: 1rem; */
}

#advanced-ahoc {
  font-size: 13px;
  font-weight: 450;
}

#search-result .p-button {
  background-color: transparent !important;
  background-image: none;

}

#search-result .p-button:hover {
  background-color: transparent !important;
  background: transparent !important;

}

#search-result .p-button:focus {
  outline: none !important;
  box-shadow: none;
}

.center {
  text-align: center !important;
}

.rectangle-a1 {
  position: absolute;
  right: 0%;
  top: 0%;
}

.rectangle-b1 {
  position: absolute;
  top: 0%;
  z-index: -1;
}

.Adhocsearch {
  height: 86vh;
  overflow-y: scroll;
}

.cor-main-data {
  display: flex;
}

.cor-first-box {
  width: 100%;
  height: 100%;
}

.cor-second-box {
  width: 100%;
  height: 100%;
}

.back-but {
  color: #091F41;
  background-color: aquamarine !important;
}

@keyframes moveLeftRight {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-10px);
  }
}

.chec-box {
  justify-content: center;
  margin-top: 20px;
  display: flex;
  gap: 20px;
}




.p-checkbox .p-checkbox-box {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: transparent 1px solid;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: #fff !important;
  border-color: transparent !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #124A99 !important;
}

.p-button {
  /* display: inline-flex !important; */
  align-items: center !important;
  justify-content: center !important;
}








.back-but-chang {
  margin-top: 20px;
  margin-left: 50px;
  cursor: pointer;
}


#ps-text {
  font-weight: bold;
}

#ad-fm {
  margin-top: -0.2% !important;
}

@media (max-width:1023px) {

  .unique-label-drops {
    font-size: 15px !important;
  }
}

@media (max-width:768px) {
  .unique-individual {
    margin: 0px !important;
  }

  .unique-corporate {
    margin: 0px !important;
  }

  .unique-adhoc-input {
    display: none !important;
  }

  .unique-adhoc-button {
    margin-top: 0px !important;
  }
}

@media (max-width: 575px) {

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
    font-size: 14px;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .update-bn-beneficiary .pi {
    font-size: 36px !important;
  }
}

@media (max-width: 575px) {
  .para {
    font-size: 14px !important;
  }
  .unique-label-drops {
    font-size: 14px !important;
  }

  .unique-form {
    row-gap: 0px !important;
  }

  .label-form,
  .exact-name-h2 {
    font-size: 14px !important;
  }

  .para {
    font-size: 14px !important;
  }

  .p-toast {
    top: 9% !important;
    width: 75% !important;
  }

  .p-toast .p-toast-message .p-toast-icon-close {
    width: 1rem !important;
    height: 1rem !important;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    width: 1rem !important;
    height: 1.3rem !important;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-size: 14px !important;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    font-size: 13px !important;
  }

  /* .p-toast .p-toast-message.p-toast-message-warn {
    font-size: 14px !important;
  }

  .p-toast .p-toast-message.p-toast-message-success {
    font-size: 14px !important;
  } */
  /* 
  .p-toast .p-toast-message .p-toast-message-content {
    padding: 0.5rem !important;
  } */

  .width-height-chart h4 {
    font-size: 14px !important;
  }

  .cus-info1 {
    font-size: 12px !important;
    padding: 0rem 0.6rem !important;
    display: block !important;
  }

  .cus-info2 {
    padding: 0rem !important;
  }

  .cus-info1 label {
    font-size: 13px !important;
  }

  .chec-box {
    display: block;
    gap: 10px;
    width: max-content;
  }

  .ind-corpo-both {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
  }

  .btn-arrow-right {
    position: relative;
    background-color: #124A99;
    color: white;
    border: none;
    height: 32px;
    font-size: 12px;
  }

  .btn-arrow-right .pi {
    font-size: 12px;
  }

  .check-lab {
    font-size: 14px !important;
    z-index: 1;
  }

  .input-adhoc {
    padding: 0.5rem !important;
  }

  .Advancedsearch-btn {
    width: 2.5rem;
    height: 2.5rem;
    right: -2% !important;
    top: 44%;
  }

  .Advancedsearch-btn .pi {
    font-size: 15px !important;
  }

  .Adthoc-input {
    padding-top: 4% !important;
  }

  .act-pending {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .act-pending.formgrid.grid .col-12 {
    width: 100% !important;
  }

  .apg-q {
    font-size: 16px !important;
  }

  .form-btns {
    width: 6rem !important;
    font-size: 12px !important;
  }

  .form-btns .pi {
    font-size: 12px !important;
  }

  .search-ctrl {
    width: 100% !important;
  }

  .as-mi {
    font-size: 12px !important;
  }

  .as-mi-header {
    font-size: 14px !important;
  }

  .profile-type-size {
    width: 100% !important;
  }

  .form-btn-end {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .sub-btn-r .p-button {
    font-size: 12px;
    width: 6rem !important;
  }

  #both {
    width: 6rem !important;
    font-size: 14px !important;
  }

  .fnm {
    width: 18rem !important;
    ;
  }

  .trans-search {
    font-size: 14px !important;
  }

  .trans-search {
    font-size: 12px !important;
    width: 6rem !important;
  }

  .trans-search .pi {
    font-size: 12px !important;
  }

}




@media (min-width: 576px) and (max-width: 749px) {
  .p-toast {
    top: 9% !important;
    width: 75% !important;
  }

  #eee {
    flex-wrap: nowrap !important;
  }

  .apg-q {
    font-size: 18px !important;
  }

  .label-form,
  .exact-name-h2 {
    font-size: 16px !important;
  }

  .check-lab {
    font-size: 14px !important;
  }

  .input-adhoc {
    padding: 0.8rem !important;
  }

  .Advancedsearch-btn {
    width: 3rem;
    height: 3rem;
    right: -2% !important;
    top: 40%;
  }

  .Advancedsearch-btn .pi {
    font-size: 20px !important;
  }

  #both {
    width: 9.5rem !important;
    font-size: 15px !important;
  }

  .fnm {
    width: 28.5rem !important;
  }

  .trans-search {
    font-size: 14px !important;
  }
}


@media (min-width: 750px) and (max-width: 1023px) {
  .p-toast {
    top: 9% !important;
    width: 75% !important;
  }

  .chec-box {
    display: block;
    gap: 10px;
    width: max-content;
  }

  #check-lab {
    margin-top: 0.6rem !important;
  }

  .ind-corpo-both {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
  }

  .apg-q {
    font-size: 18px !important;
  }

  .label-form,
  .exact-name-h2 {
    font-size: 16px !important;
  }

  .Adhoc-corpo-more-info.formgrid.grid .col-12 {
    width: 100% !important;
  }



  .check-lab {
    font-size: 14px !important;
  }

  .input-adhoc {
    padding: 0.8rem !important;
  }

  .Advancedsearch-btn {
    width: 3rem;
    height: 3rem;
    right: -2% !important;
    top: 39%;
  }

  .Advancedsearch-btn .pi {
    font-size: 20px !important;
  }

  .Beneficiary-tet {
    font-size: 11px !important;
  }

  .b-t {
    font-size: 11px !important;
    margin-left: 0.5rem !important;
  }

  #both {
    width: 9.5rem !important;
    font-size: 15px !important;
  }

  .fnm {
    width: 28.5rem !important;
  }

  .trans-search {
    font-size: 14px !important;
  }
}