#pdf-content .p-datatable {
    height: 60vh;
}
.unique_see_Pass{
    border: 1px solid red !important;
}
#progress .p-progressbar .p-progressbar-value {
    background-color: #124A99;
}

#branches .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
    text-align: center !important;
}

#country-area .p-datatable>.p-datatable-wrapper {
    height: 33.5vh !important;
}
  
.unique-h .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    background-color: transparent !important;
    background: transparent !important;
    border-color: #ced4da !important;
}

.unique-h .p-multiselect:not(.p-disabled):hover {
    border: 1px solid #ced4da !important;
    background-color: transparent !important;
    background-color: transparent !important;
}

.tab-head-btn .p-button .p-button-icon {
    color: #091f41 !important;
}
.unique-h{
    gap: 15px !important;
}
.see_Passs {
    display: flex;
    align-items: center;
    justify-content: center;
}
.see_Passs i {
    border: 1px solid rgb(211, 215, 225);
    border-left: none;
    height: 40px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 3px 3px 0px;
}
@media screen and (max-width:575px) {
 .mob-unique-userbtn {
    display: flex !important;
    justify-content: center !important;
 }
 .unique-d {
    position: relative;
    top: 70px !important;
 }
 .unique-h{
    position: unset !important;   
    gap: 15px !important;
 }
} 
@media (max-width: 650px){
    .unique-h .flex {
        flex-direction: column;
        gap: 15px !important;
    }
    .unique-h{
        gap: 15px !important;
    }
    .mob-unique-userbtn {
        display: flex !important;
        justify-content: center !important;
     }
}
@media (min-width: 800px) and (max-width: 950px){
    .unique-h .flex {
        flex-direction: column;
        gap: 15px !important;
    }
    .mob-unique-userbtn {
        display: flex !important;
        justify-content: center !important;
    }
     .unique-h{
        gap: 15px !important;
    }
}
