.rectangle2 {
  position: absolute;
  top: 0;
  right: 0;
}

.rectangle1 {
  position: absolute;
  top: 0;
  left: 0;
}

.open-new {
  margin-top: 50px;
}

.export-box {
  margin-top: 50px;

}

.table-header-Role {
  gap: 30px;

}

.filter {
  display: flex;
  margin-top: 20px;
}

.filter-user {
  margin-left: 50px;
}

.filter-button {
  margin-top: 30px;
  margin-bottom: 30px;
}

.export-btn {
  color: #fff;
  width: 70px;
  border: #091F41;
  border-radius: 3px;
}

/* .p-datatable .p-datatable-header{
  background-color: #ffff;
} */

.rect-a1 {
  /* background-color: red; */
  position: absolute;
  left: 0%;
  top: 78%;
  height: 5rem;
}

.rect-b1 {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 0;
  height: 12rem;
}

.rectangle-c1 {
  position: absolute;
  right: 0%;
  top: 18%;
  z-index: 1;
  height: 10rem;
}

.border-btm-datatble .p-datatable .p-datatable-tbody>tr>td {
  border: none !important;
  border-bottom: 1px solid #e9ecef !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
  border: none !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  padding-top: 0.7rem !important;
  padding-bottom: 0.6rem !important;
}

.border-btm-datatble1 .p-datatable .p-datatable-tbody>tr>td {
  padding-top: 0.4rem !important;
  padding-bottom: 0.3rem !important;
}

/* @media (max-width: 575px) { 
  .unique-data-responses {
    position: relative;
    top: 60px;
  }
} */


@media (max-width: 1450px) {
  .filter-user {
    margin-left: 20px;

  }

}

@media (max-width: 770px) {
  .filter {
    flex-direction: column;

  }

  .filter-user {
    margin-left: 0px;
    margin-top: 10px;
  }
}

@media (max-width: 1030px) {
  .filter {
    flex-direction: column;

  }

  .filter-user {
    margin-left: 0px;
    margin-top: 10px;
  }
}




#role-edit-btn .p-button {
  background-color: transparent !important;
  border: none !important;
  font-size: 14px;
  font-weight: 500;
  /* border-radius: 50%; */
  padding: 0 !important;
  /* width: 45px; */
  /* height: 45px; */
}

.p-datatable .p-datatable-scrollable-body::-webkit-scrollbar {
  width: 0;
  background: transparent;
}