#risk-clarification .p-button {
  background-color: transparent !important;
  border: none !important;
  background-image: none;
  font-size: 12px;
  font-weight: 500;
}

#risk-clarification .p-button:hover {
  background-color: transparent !important;
  background: transparent !important;
}

.center {
  text-align: center !important;
}

.font-semibold-risk {
  font-weight: 600 !important;
  width: 142px;
}

.risk-expand-details-risk {
  display: flex;
  justify-content: space-around;
  width: 30%;
}

.branch-expand-details-b.abcd {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  width: 30%;
}

.branch-expand-details-a.abcd {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  width: 30%;
}

.risk-reson-websd {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.p-datatable-wrapper.risk.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  width: max-content;
}

@media (max-width: 1333px) {
  .risk-reson-websd {
    display: flex;
    flex-direction: row;
    /* align-items: center;
    gap: 63px;
    padding-left: 41px; */
  }
}

#risk-cl {
  height: 66vh !important;
}

.card-datatable {
  width: 90% !important;
  margin-left: 0% !important;
  margin-left: 3% !important;
}

.container-dt {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 87%;
  margin-left: 0%;
  margin-right: 4%;
  position: fixed;
  transition: 0.5s ease;
}

.risk-clfn-dialog {
  font-size: 14px !important;
}

@media (max-width: 575px) {

  .p-paginator .p-paginator-pages .p-paginator-page,
  .p-paginator .p-dropdown {
    display: none !important;
  }
}