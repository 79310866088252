.Acp-txt {
    font-size: 15px !important;
}

.feild-reson {
    width: 100%;
    height: auto;
    margin: 0px;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3f51b5;
    display: flex;
    gap: 10px;
    padding: 0 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    align-items: center;
}

.card-individual-pending-card {
    /* border: 1px solid #8f8f8f63; */
    /* border-radius: 15px; */
    position: relative;
    top: 63px;
    width: 100% !important;
}

.card-individual-pending-card-corporate {
    position: relative;
    top: 46px;
    width: 100% !important;
}

.Cust-info {
    font-size: 16px !important;
}

.cus-info1 {
    font-size: 16px !important;
}

.file-label {
    position: relative;
    display: inline-block;
}

.plus-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 8px;
    /* Adjust the margin as needed */
}

#cp-fileupload-rr input[type="file"] {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 600;
    color: rgb(143, 143, 143);
    paint-order: none !important;
}

#cp-fileupload-rr input[type="file"]::file-selector-button {
    border: none;
    background-color: #124A99;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
}

input[type="file"] {
    display: none;
}

/* Style the custom button */
.custom-file-input {
    border: none;
    background-color: #124A99;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
}

/* Style the icon */
.custom-file-input i {
    margin-right: 5px;
}


.his_tory .p-row-toggler .p-link {
    background: none !important;
    box-shadow: none !important;
}

.f-Dd{
    display: flex;
    flex-direction: column;
}

.big_name{
    height: 30px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    flex-wrap: wrap;
}