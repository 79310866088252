.accountOv_Main {
    width: 74%;
    background: white;
    box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
    border-radius: 8px;
    display: flex;
}

.accountOv,
.user_Profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}


.user_Profile {
    width: 50%;
}

.acc_bar_1,
.acc_bar_2 {
    width: fit-content;
    transform: rotate(270deg);
    position: relative;
    bottom: -107px;
    left: -695px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.acc_bar_2 {
    bottom: -63px;
    left: -295px;
}

.wrapper_L,
.wrapper_R {
    background: transparent;
    overflow: hidden;
    border-radius: 0px 6px 6px 0px;
}

.wrapper_L div,
.wrapper_R div {
    border-radius: 0px 6px 6px 0px !important;
}

.wrapper_L div div,
.wrapper_R div div {
    display: none !important;
}

.grf {
    border-left: 1px solid #a7a5a5;
    border-bottom: 1px solid #a7a5a5;
    height: 150px;
}

.u_P {
    position: relative;
    left: -80px;
    top: -5px;
}

.acc_Lea {
    color: rgb(119, 119, 119);
    position: relative;
    top: 30px;
    right: 75px;
    font-size: 12px;
    transform: rotate(90deg);
}



@media (max-width: 1850px) {

    .acc_bar_1 {
        left: -270px;
    }

    .acc_bar_2 {
        left: -135px;
    }

}


@media (max-width: 1150px) {
    .bar_Flex {
        flex-direction: column;
    }
}


@media (min-width: 320px) and (max-width: 420px) {
    .acc_bar_1 {
        left: -235px;
    }

    .acc_bar_2 {
        left: -140px;
    }
}












.dashboard_Main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 0;
}

.box_s {
    width: 370px;
    height: 120px;
    background: white;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
    padding: 0 25px;
    box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.box_s .img_Sec {
    width: 80px;
    min-height: 80px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box_s .value {
    width: 110px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.slide-container {
    padding: 20px 0;
}

.pre {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cic {
    padding: 20px 15px;
    display: flex;
    gap: 25px;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    font-weight: 600;
}


.dot {
    width: 10px;
    height: 10px;
    background: #9159e3;
    border-radius: 50%;
    padding: 5px;
}

.CircularProgressbar {
    width: 10rem !important;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #9159e3 !important;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #124A99 !important;
    font-weight: 600;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: #ff8b4f !important;
}

.chart_Al {
    background: white;
    border-radius: 8px;
    gap: 25px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.moB {
    display: none;
}

.dash_BreadCrumb {
    border: none;
    position: inherit;
    background: rgb(245, 246, 250);
    z-index: 99;
    width: -webkit-fill-available;
}

.co {
    display: flex;
    height: 350px;
}

.web_width {
    width: 74%;
}



@media (min-width: 1500px) {

    .box_s {
        width: 23%;
        height: 120px;
        background: white;
        display: flex;
        align-items: center;
        border-radius: 8px;
        justify-content: space-between;
        padding: 0 0px 0 14px;
        box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
    }

    .dashboard_Main,
    .flex_Warp_ {
        flex-wrap: wrap;
    }
}
@media (max-width: 1500px) {

    .box_s {
        width: 23%;
        height: 120px;
        background: white;
        display: flex;
        align-items: center;
        border-radius: 8px;
        justify-content: space-between;
        padding: 0 0px 0 14px;
        box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
    }

    .dashboard_Main,
    .flex_Warp_ {
        flex-wrap: wrap;
    }
}

@media (min-width: 1487px) {
    .chart_hide-visiable {
        display: none;
    }
}
@media (max-width: 1487px) {
    .chart_hide-visiable_one {
        display: none;
    }
}
@media (max-width: 950px) {
    .input.chart_Al._px {
        display: none;
    }
}

@media (min-width: 1235px) {
    .chart-width {
        width: 50% !important;
    }
}

@media (max-width: 1235px) {
    .box_s {
        width: 48% !important;
    }

    .flex_Warp {
        flex-wrap: wrap;
    }

    .fu {
        width: 100% !important;
    }

    .s-S {
        justify-content: space-between;
    }
}


@media (max-width: 600px) {

    .f-D_C {
        flex-direction: column;
    }

    .weB {
        display: none;
    }

    .moB {
        display: block;
    }

    .active {
        display: flex !important;
        align-items: center;
        background: unset !important;
        justify-content: center;
    }

    .box_s {
        gap: 15px;
        width: 12rem !important;
        height: auto;
        padding: 25px;
        flex-direction: column;
    }

    .value {
        align-items: center;
    }

}

.draggable {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: lightblue;
    border: 2px solid blue;
    text-align: center;
    line-height: 100px;
    cursor: move;
}
