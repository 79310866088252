.alertCriteria {
  width: 23px !important;
  height: 23px;
  background: transparent !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
}

#notification-preference .p-datatable .p-datatable-thead>tr>th {
  background-color: #124A99;
  text-align: center !important;
  align-items: center;
}

.notification-icon-bg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
}

.alertCriteria.notification {
  width: 23px !important;
  height: 23px;
  background: transparent !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
}

.html-tab-content.text-center.w-full.border-collapse.header-name {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

td.border-t-0.px-6.align-middle.border-l-0.border-r-0.text-sm.whitespace-no-wrap.p-5 {
  padding-left: 3rem !important;
  text-align: left;
  color: gray;
}

thead.w-max {
  height: 4rem;
}

#notification-preference .p-datatable .p-datatable-tbody tr:nth-child(odd) {
  background-color: transparent;
}

#notification-preference .p-datatable .p-column-header-content {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-flex-mainsec {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: 16px;
}

.notificiation-btn {
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  padding-bottom: 39px;
  flex-wrap: wrap;
  align-content: center;
  gap: 16px;
  justify-content: space-around;
  align-items: center;
}

.card.card-noti.w-full {
  position: relative;
  top: 65px !important;
}

#notification-preference .p-column-title {
  font-size: 17px;
}

.center {
  text-align: center !important;
}

.a-col .p-radiobutton-box:nth-child(0) {
  display: none;
  background-color: red !important;
}

.header-row {
  background-color: #124A99;
  color: white;
  font-size: 14px !important;
}

input[type=radio] {
  accent-color: #124A99;
  width: 0.7rem;
}

tr:nth-child(even) {
  background-color: #f8f8f8 !important;
}

.AP-th-div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  align-content: flex-start;
  gap: 10px;
}

@media (min-width: 320px) and (max-width: 575px) {
  .AP-th {
    font-size: 12px !important;
  }

  .html-tab-content {
    width: max-content !important;
  }

  .menuItem .mainSec.noti {
    height: 80vh !important;
    overflow: auto;
  }

  .card.card-Adhoc.w-full {
    position: relative;
    top: 65px;
    margin: 0px !important;
  }

  .html-table {
    /* overflow-y: scroll !important; */
    overflow-x: scroll !important;
  }
}

@media (min-width: 576px) and (max-width: 749px) {
  .AP-th {
    font-size: 14px !important;
  }

  .menuItem .mainSec.noti.noti {
    height: 80vh !important;
    overflow: auto;
  }

  .card.card-Adhoc.w-full {
    position: relative;
    margin: 0px !important;
    top: 65px;
  }

  .html-tab-content {
    width: max-content !important;
  }

  .html-table {
    /* overflow-y: scroll !important; */
    overflow-x: scroll !important;
  }
}

@media (min-width: 1023px) and (max-width: 1128px) {
  /* .card.card-Adhoc.w-full {
    position: relative;
    margin: 0px !important;
    top: 65px;
  } */

  .menuItem .mainSec.noti {
    height: 80vh !important;
    overflow: auto;
  }
}

@media (min-width: 1129px) and (max-width: 4128px) {
  .card.card-Adhoc.w-full {
    position: relative;
    margin: 0px !important;
    top: -9px;
  }

  .menuItem .mainSec.noti {
    height: 80vh !important;
    overflow: auto;
  }
}

@media (min-width: 320px) and (max-width: 1110px) {
  .html-table {
    /* overflow-y: scroll !important; */
    overflow-x: scroll !important;
  }

  th.p-2.AP-th {
    width: 13rem;
  }

  th.abgj.AP-th {
    width: 13rem;
  }

  th.abgj1.AP-th {
    width: 19rem;
  }
}

@media (min-width: 320px) and (max-width: 1074px) {
  .menuItem .mainSec.noti {
    height: 80vh !important;
    overflow: auto;
  }
}

@media (min-width: 750px) and (max-width: 1023px) {
  .menuItem .mainSec.noti {
    height: 80vh !important;
    overflow: auto;
  }

  .AP-th {
    font-size: 15px !important;
  }

  .card.card-noti.w-full {
    position: relative;
    margin: 0px !important;
    top: 65px;
  }

  .html-tab-content {
    width: max-content !important;
  }

  .html-table {
    /* overflow-y: scroll !important; */
    overflow-x: scroll !important;
  }
}

tr.header-rows {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 24px;
  justify-content: space-evenly;
  flex-direction: row;
  align-content: center;
}

@media (min-width: 320px) and (max-width: 3130px) {
  .mainSec.noti .p-breadcrumb {
    z-index: 9;
    position: fixed;
    width: -webkit-fill-available;
  }
}