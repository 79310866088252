.baNNER_Sec {
  /* height: 62vh; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  /* background: #f9f8ff; */
  padding: 50px 25px;
  margin: auto;
  max-width: 1400px;
}

.baNNER_Sec_Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  background: #f9f8ff;
}

.baNNER_Sec_Heading_Main {
  font-size: 40px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.baNNER_Sec_Heading_Sub {
  width: 85%;
  color: #858585;
  font-size: 24px;
  line-height: 45px;
  font-weight: 600;
}

.baNNER_Sec_BTN {
  display: flex;
  gap: 20px;
}

.baNNER_Sec_BTN button {
  font-weight: 600;
}

.button-demo {
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  color: #124A99;
  border-radius: 25px;
  border: 1px solid #124A99;
  background: transparent;
  padding: 0 15px;
}

.button-get {
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #124A99;
  border-radius: 25px;
  border: none;
  padding: 0 15px;
}

.baNNER_Sec_Right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f8ff;
}

.baNNER_Sec_Right img {
  width: auto;
  height: 350px;
}

.banner-100k {
  font-size: 18px;
  font-weight: 600;
  color: #858585;
}


.bam_IMg {
  height: 65px;
  border-radius: 50%;
}


@media (max-width: 1100px) {
  .baNNER_Sec {
    flex-wrap: wrap;
    /* height: 50vh; */
  }

  .baNNER_Sec_Heading_Main {
    font-size: 30px;
    gap: 5px;
  }

  .baNNER_Sec_Heading_Sub {
    font-size: 18px;
    line-height: 35px;
  }

  .baNNER_Sec_Right img {
    width: auto;
    height: 270px;
  }

  .baNNER_Sec_Left {
    gap: 25px;
  }

}

@media (max-width: 970px) {
  .baNNER_Sec_Right {
    width: 100%;
  }
}

@media (max-width: 963px) {
  .baNNER_Sec {
    height: auto;
  }

  /* r */

  .baNNER_Sec_Left {
    width: 100%;
    text-align: center;
  }

  .baNNER_Sec_Right {
    width: 100%;
  }

  .baNNER_Sec_BTN,
  .image-container {
    justify-content: center !important;
  }

}

@media (max-width: 334px) {
  .baNNER_Sec_Right img {
    width: 270px;
  }

  .baNNER_Sec_Right {
    width: 100%;
  }
}

.image-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

@media (max-width: 600px) {

  .baNNER_Sec_Heading_Main {
    font-size: 22px;
    gap: unset;
  }

  .baNNER_Sec_Heading_Sub {
    font-size: 14px;
    /* text-align: -webkit-center; */
    line-height: 30px;
  }

  .baNNER_Sec_BTN {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
  }

  .image-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }

  .baNNER_Sec_Heading_Sub br {
    display: none;
  }

  .baNNER_Sec_Right img {
    height: 270px;
  }

  .banner-100k {
    font-size: 14px;
  }

  .baNNER_Sec_Left {
    width: unset;
    text-align: unset;
  }

  /* .baNNER_Sec_Right {
    width: unset;
  } */

  .baNNER_Sec_BTN,
  .image-container {
    justify-content: unset !important;
  }

}
















.logocontainer {
  display: flex;
  max-width: 1400px;
  padding: 20px 0px 0px 0px;
  background-color: #f9f8ff;
  margin: 0 auto;
}

.logocolumn {
  flex: 1;
  padding: 20px;
}

.logocolumn:nth-child(odd) {
  background-color: #f9f8ff;
}

.banner-finance {
  font-size: 37px;
  font-weight: 600;
  color: #4e4e4e;
}

.solution-banner {
  font-size: 37px;
  font-weight: 600;
  color: #124A99;
}

.banner-content {
  font-size: 22px;
  font-weight: 600;
  width: 88%;
  color: #858585;
  line-height: 45px;
  text-align: justify;
}

.image-lap {
  position: relative;
  z-index: 1;
}

.image-overlap {
  position: relative;
  right: 22px;
}

.image {
  margin-left: -47px;
}



@media (max-width: 1399px) and (min-width: 1201px) {
  .logocontainer {
    display: flex;
    max-width: 85%;
    padding: 20px 0px 0px 0px;
    background-color: #f9f8ff;
    margin: 0 auto;
  }

  .banner-finance {
    font-size: 26px;
    font-weight: 600;
  }

  .solution-banner {
    font-size: 26px;
    font-weight: 600;
  }

  .banner-content {
    font-size: 18px;
    font-weight: 600;
    width: 80%;
    line-height: 36px;
  }

  /* .button-get {
    width: 135px;
    height: 38px;
    font-size: 14px;
    font-weight: 600;
  }

  .button-demo {
    width: 135px;
    height: 38px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 23px;
  } */

  /* .image-container {
    position: relative;
    top: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  } */

  .image-lap {
    position: relative;
    z-index: 1;
    height: 60px;
    width: 60px;
  }

  .image-overlap {
    position: relative;
    right: 18px;
    height: 60px;
    width: 60px;
  }

  .image {
    margin-left: -40px;
    height: 60px;
    width: 60px;
  }

  /* .banner-100k {
    font-size: 18px;
    font-weight: 600;
    color: #858585;
  } */

  .column-2-banner {
    height: 450px;
    width: 475px;
  }
}

@media (max-width: 1200px) and (min-width: 1032px) {
  .logocontainer {
    display: flex;
    max-width: 85%;
    padding: 20px 0px 0px 0px;
    background-color: #f9f8ff;
    margin: 0 auto;
  }

  .banner-finance {
    font-size: 23px;
    font-weight: 600;
  }

  .solution-banner {
    font-size: 23px;
    font-weight: 600;
  }

  .banner-content {
    font-size: 16px;
    font-weight: 600;
    width: 90%;
    line-height: 28px;
  }

  /* .button-get {
    width: 115px;
    height: 35px;
    font-size: 14px;
    font-weight: 600;
  }

  .button-demo {
    width: 115px;
    height: 35px;
    font-size: 14px;
    font-weight: 700;
    margin-left: 28px;
  } */

  /* .image-container {
    position: relative;
    top: 4%;
  } */

  .image-lap {
    position: relative;
    z-index: 1;
    height: 55px;
    width: 55px;
  }

  .image-overlap {
    position: relative;
    right: 18px;
    height: 55px;
    width: 55px;
  }

  .image {
    margin-left: -40px;
    height: 55px;
    width: 55px;
  }

  /* .banner-100k {
    text-align: justify;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    bottom: 8%;
    right: 4%;
    font-size: 16px;
    font-weight: 600;
  } */

  .column-2-banner {
    height: 420px;
    width: 400px;
  }
}

@media (max-width: 1031px) and (min-width: 769px) {
  .logocontainer {
    display: flex;
    max-width: 85%;
    padding: 20px 0px 0px 0px;
    background-color: #f9f8ff;
    margin: 0 auto;
  }

  .banner-finance {
    font-size: 25px;
    font-weight: 600;
  }

  .solution-banner {
    font-size: 25px;
    font-weight: 00;
  }

  .banner-content {
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    line-height: 25px;
  }

  /* .button-get {
    width: 100px;
    height: 30px;
    font-size: 12px;
    font-weight: 600;
  }

  .button-demo {
    width: 100px;
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    margin-left: 28px;
  } */

  /* .image-container {
    position: relative;
    top: 4%;
  } */

  .image-lap {
    position: relative;
    z-index: 1;
    height: 50px;
    width: 50px;
  }

  .image-overlap {
    position: relative;
    right: 18px;
    height: 50px;
    width: 50px;
  }

  .image {
    margin-left: -40px;
    height: 50px;
    width: 50px;
  }

  /* .banner-100k {
    text-align: justify;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    bottom: 4%;
    left: 10%;
    right: 0;
    font-size: 14px;
    font-weight: 600;
  } */

  .column-2-banner {
    height: 290px;
    width: 280px;
    padding-left: 20px;
    padding-top: 20px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .logocontainer {
    display: flex;
    flex-direction: column;
    max-width: 85%;
    padding: 0px 0px 0px 00px;
    background-color: #f9f8ff;
    margin: 0 auto;
  }

  .logocolumn {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    align-items: center;
    /* padding-left: 0px; */
  }

  .banner-finance {
    font-size: 28px;
    font-weight: 600;
  }

  .solution-banner {
    font-size: 28px;
    font-weight: 600;
  }

  .banner-content {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    line-height: 28px;
    display: flex;
    justify-content: center;
  }

  /* .button-get {
    width: 130px;
    height: 45px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
  }

  .button-demo {
    width: 130px;
    height: 45px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 28px;
    margin-top: 15px;
  } */

  /* .image-container {
    position: relative;
    margin-top: 30px;
    width: 100%;
    height: 80px;
    justify-content: center;
  } */

  .image-lap {
    position: relative;
    z-index: 1;
    height: 50px;
    width: 50px;
  }

  .image-overlap {
    position: relative;
    right: 18px;
    height: 50px;
    width: 50px;
  }

  .image {
    margin-left: -40px;
    height: 50px;
    width: 50px;
  }

  /* .banner-100k {
    display: inline-flex;
    right: 0;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  } */

  .column-2-banner {
    height: 300px;
    width: 320px;
    display: flex;
    margin: auto;
  }
}

@media (max-width: 575px) and (min-width: 320px) {
  .logocontainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0px 0px 0px 00px;
    background-color: #f9f8ff;
    margin: 0 auto;
  }

  .logocolumn {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    align-items: center;
    /* padding-left: 0px; */
  }

  .logocolumn h2 {
    /* display: flex; */
    flex-direction: row;
    text-align: center;
    margin: auto;
  }

  .banner-finance {
    font-size: 28px;
    font-weight: 600;
  }

  .solution-banner {
    font-size: 28px;
    font-weight: 600;
  }

  .banner-content {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    line-height: 25px;
  }

  /* .button-get {
    width: 120px;
    height: 45px;
    font-size: 12px;
    font-weight: 700;
    margin-top: 15px;
  }

  .button-demo {
    width: 120px;
    height: 45px;
    font-size: 12px;
    font-weight: 700;
    margin-left: 28px;
    margin-top: 15px;
  } */

  /* .image-container {
    position: relative;
    margin-top: 30px;
    width: 100%;
    height: 80px;
    justify-content: center;
    gap: 0px;
  } */

  .image-lap {
    position: relative;
    z-index: 1;
    height: 40px;
    width: 40px;
  }

  .image-overlap {
    position: relative;
    right: 18px;
    height: 40px;
    width: 40px;
  }

  .image {
    margin-left: -40px;
    height: 40px;
    width: 40px;
  }

  /* .banner-100k {
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    text-align: left;
  } */

  .column-2-banner {
    height: 270px;
    width: 250px;
    display: flex;
    margin: auto;
  }
}