.head_r {
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 30px; */
    border-bottom: 2px solid rgb(211, 215, 225);
}

.sing_up_main_Dialogs .p-dialog-content,
.sing_up_main_Dialogs .p-dialog-footer {
    padding: 20px !important;
    width: 30rem;
}

.sing_up_main_Dialogs.p-dialog .p-dialog-header-icons {
    position: relative;
    top: 5px;
    display: none !important;
}

.sing_up_main_Dialog.payment .p-dialog-header {
    background-color: #124A99 !important;
    color: #fff !important;
    padding: 0 20px;
    height: 4rem;
    align-items: center;
}

.sing_up_main_Dialogs.p-dialog .p-dialog-header {
    background-color: #124A99 !important;
    color: white !important;
    height: 4rem;
    border-bottom: 1px solid gray !important;
}

button.p-button.p-component.p-confirm-dialog-reject.p-button-text {
    background: none !important;
    background-image: none !important;
    background-color: transparent !important;
    border: 0px;
}

.p-confirm-dialog-reject span.p-button-label.p-c {
    color: black !important;
    font-weight: bold;
}

.feild.feild-m.main-flex-p-2.p-4 {
    padding: 20px !important;
    gap: 20px !important;
}

.sing_up_main_Dialogs .p-dialog-footer {
    padding-left: 20px !important;
    padding-bottom: 20px !important;
    padding-right: 20px !important;
    padding-top: 0px !important;
}

.pay_Ment_ {
    display: flex;
    justify-content: center;
    align-items: normal;
    gap: 65px;
    padding: 50px 20px;
    flex-wrap: wrap;
}


.pay_Ment_O {
    width: 300px;
    height: auto;
    min-height: 53vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #124A99;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
    transition: border-color 0.3s ease;
}

.pay_Ment_O_Top {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    background: #124A99;
    color: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
}

.pay_Ment_Bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* color: rgb(133, 133, 133); */
    color: #858585;
    padding: 20px;
    padding-right: 13px;
}

.pay_Ment_Btn {
    margin: 15px 0;
    height: 35px;
    width: 120px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border: none;
    border-radius: 6px;
    background: #124A99;
}

.pay_Ment_Dot {
    width: 10px;
    height: 12px;
    border-radius: 50%;
    border: unset;
}

.pay_Ment_Slider {
    display: none;
}

.pay_Ment_With_Out_Slider {
    display: flex;
}

.img-header {
    cursor: pointer;
}

@media (max-width:785px) {
    .pay_Ment_Slider {
        display: flex;
    }

    .pay_Ment_With_Out_Slider {
        display: none;
    }
}

@media (max-width:420px) {
    .pay_Ment_O {
        width: 100%;
    }
}

@media (max-width:600px) {
    .head_r .img-header {
        width: 130px !important;
    }
}

.payment-page-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px !important;
    box-shadow: none !important;
    margin-top: 25px !important;
}

.payment-page-card.p-card .p-card-content {
    padding: 0rem 0 !important;
}

.payment-page-card .p-card .p-card-body {
    padding: 0rem !important;
}

.payment-page-container {
    width: -webkit-fill-available !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease;
}

.daily-one,
.daily-two,
.daily-three,
.daily-four {
    padding: 15px !important;
}

.switch-planning .p-selectbutton .p-button.p-highlight {
    background-color: #124A99;
    border-color: #124A99;
    color: #ffffff !important;
}

.switch-planning .p-button {
    border-radius: 6px;
    width: 100px;
    height: 40px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.switch-planning .p-selectbutton .p-button {
    color: #124A99 !important;
}

.pricing {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
}

.pricing-content {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #919191;
}

.free-plan {
    background-color: #124A99;
    border-radius: 16px 16px 0px 0px !important;
    color: #ffffff;
}

.free-plan-top {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10px;
}

.free-plan-trial {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
}

.pricing-button {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    width: 120px;
    background-color: #124A99;
    border-radius: 6px;
    border-color: #124A99 !important;
    transition: background-color 0.3s ease;
}

.price-limit li::marker {
    color: #C8C8C8;
}

.pricing-boxes {
    border: 1px solid #124A99 !important;
    transition: border-color 0.3s ease;
}

.price-limit li {
    font-size: 16px;
    padding-right: 10px;
}

.price-limit {
    height: 400px;
}

.slider-buttons .p-radiobutton .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 10px;
    height: 10px;
    color: #495057;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.slider-buttons .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #124A99 !important;
    background: #124A99 !important;
}

.slider-buttons .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    transform: translateZ(0) scale(1.0, 1.0);
    visibility: hidden !important;
}

.slider-buttons .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    transform: translateZ(0) scale(1.0, 1.0);
    visibility: hidden !important;
}

.daily-plan .col-12,
.monthly-plan .col-12 {
    flex-basis: 24%;
    max-width: 48%;
    margin-right: 0%;
}

.shadow-2 {
    width: 100% !important;
    height: 420px;
}

.unique-slider.grid {
    column-gap: 20px;
    flex-wrap: nowrap !important;
}

@media (max-width:1884px) {
    .card-width-change {
        width: 300px;
    }
}

@media (max-width:1410px) {
    .card-width-change {
        width: 250px;
    }

    .unique-slider.grid {
        column-gap: 10px;
        flex-wrap: nowrap !important;
    }
}

@media (max-width:1200px) {
    .card-width-change {
        width: 230px;
    }

    .unique-slider.grid {
        column-gap: 0px;
        flex-wrap: wrap !important;
    }
}

@media (max-width:1135px) {
    .card-width-change {
        width: 220px;
    }

    .unique-slider.grid {
        column-gap: 0px;
        flex-wrap: nowrap !important;
    }

    .shadow-2 {
        width: 100% !important;
        height: 450px;
    }
}

@media (max-width:1042px) {
    .card-width-change {
        width: 400px;
    }

    .unique-slider.grid {
        column-gap: 0px;
        flex-wrap: wrap !important;
    }

    .daily-plan .col-12,
    .monthly-plan .col-12 {
        flex-basis: 48%;
        max-width: 25%;
        margin-right: 0% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .payment-page-card {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .price-limit li {
        font-size: 14px;
    }

    .switch-planning .p-button {
        height: 40px;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
    }

    .pricing {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
    }

    .pricing-content {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .free-plan-top {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin-top: 10px;
    }

    .free-plan-trial {
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
    }

    .pricing-button {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        width: 100px;
    }
}

@media (max-width:900px) {

    .daily-plan .col-12,
    .monthly-plan .col-12 {
        flex-basis: 48%;
        max-width: 48%;
        margin-right: 0% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .price-limit {
        height: 370px;
    }

    .shadow-2 {
        height: 380px;
    }
}

@media (max-width:768px) {
    .switch-planning .p-button {
        width: 75px;
        height: 35px;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
    }

    .pricing {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
    }

    .pricing-content {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .free-plan-top {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin-top: 10px;
    }

    .free-plan-trial {
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
    }

    .pricing-button {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        width: 100px;
    }
}

@media (max-width:600px) {
    .unique-slider {
        display: none;
    }

    .shadow-2 {
        height: 350px;
    }
}

@media (max-width:500px) {
    .payment-page-card {
        border: 0px !important;
        box-shadow: none !important;
        margin: 0px !important;
    }
}

@media (max-width:430px) {
    .price-limit li {
        font-size: 12px;
    }

    .shadow-2 {
        height: 350px;
    }

    .payment-page-card {
        margin: 0px;
        border-radius: 0px !important;
    }

    .switch-planning .p-button {
        width: 75px;
        height: 30px;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
    }

    .pricing {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
    }

    .pricing-content {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
    }

    .free-plan {
        border-radius: 16px 16px 0px 0px !important;
        color: #ffffff;
    }

    .free-plan-top {
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        margin-top: 5px;
    }

    .free-plan-trial {
        font-size: 12px;
        font-weight: 500;
        line-height: 25px;
    }

    .pricing-button {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        width: 100px;
    }
}


/* PaypalView css */
.card-payment {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.card-paypal {
    border-radius: 10px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.paypal-head {
    font-size: 22px;
    font-weight: bold;
    border-bottom: 1px solid grey;
}

.provider-topic {
    font-size: 18px;
    font-weight: 500;
}

.paypal-pagee {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.provider {
    width: 750px;
}

.payment-details {
    display: grid;
    row-gap: 10px;
}

.unique-step .p-steps .p-steps-item.p-highlight .p-steps-title {
    color: #124A99 !important;
}

.unique-step .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #124A99;
    color: white;
}

@media (max-width:1124px) {
    .paypal-pagee {
        padding: 0px;
    }
}

@media (max-width:1024px) {
    .paypal-pagee {
        box-shadow: none;
        margin: 0px;
    }

    .card-payment {
        margin-top: 0px !important;
    }

    .paypal-head {
        font-size: 20px;
    }

    .provider-topic {
        font-size: 16px;
        font-weight: 500;
    }

    .unique-step .p-steps ul {
        width: 80%;
        margin: auto;
        display: flex;
        row-gap: 16px !important;
    }
}

@media (max-width:768px) {
    .paypal-head {
        font-size: 18px;
    }

    .provider-topic {
        font-size: 16px;
        font-weight: 500;
    }

    .paypal-loginbtn .p-button {
        font-size: 16px !important;
    }
}

@media (max-width:550px) {
    .unique-step .p-steps ul {
        display: grid !important;
        row-gap: 16px !important;
    }
}

@media (max-width:430px) {
    .paypal-head {
        font-size: 16px;
    }

    .provider-topic {
        font-size: 14px;
        font-weight: 500;
    }

    .paypal-loginbtn .p-button {
        font-size: 14px !important;
    }
}

@media (max-width:910px) {
    .provider {
        width: 550px;
    }
}

@media (max-width:700px) {
    .provider {
        width: 400px;
    }
}

@media (max-width:550px) {
    .provider {
        width: 300px;
    }
}

@media (max-width:430px) {
    .provider {
        width: 200px;
    }

    .card-paypal {
        margin: 0px !important;
        padding: 16px !important;
    }
}


.a_Pay {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 50px;
}

.a_Pay_Sub_H {
    color: #919191;
    width: 100%;
}

.a_Pay_Main {
    width: 100%;
    display: flex;
    animation: none;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.a_Pay_Head {
    font-size: 50px;
    color: #000000;
}

.sel_BTN {
    padding: 50px 0;
}

.sel_BTN .p-selectbutton .p-button.p-highlight {
    background-color: #124A99;
    border-color: #124A99;
    color: #ffffff;
}

.sel_BTN .p-button {
    border-radius: 6px;
    width: 100px;
    height: 40px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

.sel_BTN .p-selectbutton .p-button {
    color: #124A99;
}

.a_Pay_Card {
    width: 350px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #124A99;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
    transition: border-color 0.3s ease;
}

.a_Pay_Plan {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 25px;
    padding: 15px 0;
}

.a_Pay_Amo {
    display: flex;
    gap: 10px;
    align-items: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 15px 0;
    font-weight: 600;
}

.a_Pay_D {
    position: relative;
}

.a_Pay_Amo_C {
    font-size: 60px;
}

.a_Pay_Pr {
    color: #858585;
    font-weight: normal;
}

.a_Pay_Opc_ {
    height: 33vh;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 15px 0 0 0;
}

.a_Pay_Opc {
    height: 30px;
    display: flex;
    align-items: center;
    color: #858585;
    font-size: 14px;
}

.a_Pay_Foo {
    padding: 15px 0;
}

.a_Pay_Foo_B {
    width: 100%;
    font-weight: 600;
}




.doT_B {
    display: flex;
    display: flex;
    gap: 20px;
}

.doT_BTN {
    width: 10px;
    height: 10px;
    padding: unset;
    border-radius: 50px;
}

.l_INK {
    color: rgb(69, 128, 194);
    font-weight: 600;
    cursor: pointer;
}

@media (max-width:1755px) {
    .a_Pay_Main {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width:800px) {
    .a_Pay {
        flex-direction: column;
        overflow: auto;
        justify-content: flex-start;
        gap: unset;
        padding: 25px 50px;
    }

    .a_Pay_Top_C {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .a_Pay_Head {
        font-size: 40px;
    }
}

@media (max-width :575px) {
    .a_Pay_Head {
        font-size: 25px;
    }

    .a_Pay_Sub_H {
        font-size: 14px;
    }

    .sel_BTN {
        padding: 30px 0;
    }

    .a_Pay_Plan {
        font-size: 18px;
    }

    .a_Pay_Amo_C {
        font-size: 30px;
    }

    .a_Pay_Opc_ {
        height: 30vh;
        padding: 10px 0 0 0;
    }

    .a_Pay_Foo {
        padding: 10px 0;
    }

    .a_Pay_Card {
        width: 300px;
    }

    .a_Pay_Main {
        gap: 25px;
    }
}

@media (max-width :420px) {

    .a_Pay {
        padding: 25px;
    }

    .a_Pay_Card {
        width: 100%;
    }
}