.unique-dia {
  width: 100%;
  height: 70px !important;
  display: flex;
  gap: 20px !important;
  justify-content: flex-end;
  align-items: revert !important;
  padding: 0 25px !important;
}

.unique-dias {
  height: 80px !important;
}

.unique-menu-exp.p-menu .p-menuitem-link {  
  gap: 10px !important;
}

@media (max-width: 680px) {
  .unique-fields-row .flex{
    flex-direction: column !important;
    gap: 15px !important;
  }

}

/* .card{
  margin-bottom: 60px;
}
.p-button.p-button-secondary{
  background-color: #091F41;
}
.p-button .p-button-icon{
  color: #ffff;
}
.table-header{
  display: flex;
}

.rs-stack-item{
 position: sticky;
}


  .rs-picker-daterange-panel{
    width:250px;
    height: 300px;
  }
  .Date-label{
   margin-Left: 520px; 
   margin-top:8px ;
  }
  .open-new{
    margin-top: 30px;
  }
  .export-box{
    margin-top: 30px;

  }

  .rectangle2{
    position: absolute;
    top: 0;
    right: 0;
  }
 .rectangle1{
    position: absolute;
    top: 0;
    left: 0;
  }
  .p-toolbar{
    background-color: #ffff;
  }
  .rs-picker-toggle-caret{
  display: none;
  }
  .unique-menu-exp .p-menu .p-menuitem-link {  
    gap: 10px !important;
  }
  .unique-dia {
    width: 100%;
    height: 50px !important;
    display: flex;
    gap: 20px !important;
    justify-content: end;
    align-items: revert !important;
    padding: 0 20px;
  }
  
  @media (min-width: 320px) and (max-width: 576px){
    #glob-filter .p-inputtext{
        padding-left: 1% !important;
    }
    .country-head-content{ 
        justify-content: center !important;
        text-align: center !important;
        align-items: center !important;
    }
    #country-head{
        margin-left: 78% !important;
    }
    #country-menu-btn .new-btn, #country-menu-btn .export-btn{
        margin: 0.4rem !important;

    }
    #country-menu-btn{
        justify-content: center !important;
        padding-top: 0 !important;
    }
    #glob-filter{
        justify-content: center !important;
    }
    .country-conatent{
        flex-wrap: wrap !important;
    }
}

@media (min-width: 577px) and (max-width: 767px){
  #glob-filter .p-inputtext{
      padding-left: 1% !important;
  }
  .country-head-content{ 
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
  }
  #country-head{
      margin-left: 78% !important;
  }
  #country-menu-btn .new-btn, #country-menu-btn .export-btn{
      margin: 0.4rem !important;

  }
  #country-menu-btn{
      justify-content: center !important;
      padding-top: 0 !important;
  }
  #glob-filter{
      justify-content: center !important;
  }
  .country-conatent #risk-ht{
      display: flex !important;
  }
  
  .country-conatent{
      flex-wrap: wrap !important;
  }
} */
/* 
  @media (max-width: 800px) {
    .Date-label{
      margin-Left: 20px; 
      margin-top:8px ;
     }
  }
  @media (max-width: 915px) {
    .Date-label{
      margin-Left: 5px; 
     
      margin-top:8px ;
     
     }
     .table-header{
      flex-direction: column;
     }
  }

  @media (max-width: 1200px) {
    .Date-label{
      margin-Left: 20px; 
      margin-top:8px;
     }
  }
  @media (max-width: 1500px) {
    .Date-label{
      margin-Left: 50px; 
      margin-top:8px ;
     }
  }

  @media (max-width: 770px) {
   .table-header{
    flex-direction: column;
   }
   .Date-label{
    margin-Left: 8px; 
    margin-top:8px ;
   }
  } */


 
  