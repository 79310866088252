.slider_S {
    width: 100%;
    padding: 50px 25px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
    gap: 25px;
    max-width: 1400px;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 80px;
    flex-wrap: wrap;
}

.slider_S_blog {
    display: flex;
    flex-direction: column;
    gap: 18px;
    background: #ffff;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: 5px 5px 5px 0 #0000003b;
    transition: transform 0.3s ease-in-out;
}

.slider_S_blog:hover {
    transform: scale(1.05);
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.foo_V_blog {
    color: #093EA4;
    font-size: 16px;
    font-weight: 500;
    padding: 0 25px;
}

.slider_S_Tblog {
    width: auto;
    height: 230px;
    object-fit: cover;
}

.foo_C_blog {
    width: 70%;
    font-size: 15px;
    font-weight: 500;
    color: #858585;
    display: flex;
    justify-content: space-between;
}

.foo_blog {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 25px 25px;
}

.blog_BTN {
    width: 35px !important;
    background: transparent !important;
    border: 1px solid #093EA4 !important;
    border-radius: 50% !important;
    padding: unset !important;
}


.blog_BTN .p-button-icon {
    color: #093EA4;
}

.dot_M {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 80px;
    padding: 0px 25px 50px;
    gap: 20px;
}

.dot_M_BTN {
    width: 11px !important;
    height: 11px !important;
    border-radius: 50% !important;
    padding: unset !important;
}

.blog_Items .slider_S_blog {
    display: flex !important;
}

.blog_Items .slick-track {
    height: 42vh !important;
    display: flex;
    gap: 30px;
}

.bLOG_H {
    position: relative;
    top: 80px;
}

.bLOG {
    width: 67px;
    height: 6px;
    background: #093EA4;
    margin: auto;
}

.bLOG div {
    background: #093EA4;
    width: 9px;
    height: 6px;
    border-radius: 50%;
    position: relative;
    left: 74px;
}

.bLOG_H_Su {
    width: 100%;
    max-width: 1400px;
    margin: auto;
    color: #858585;
    font-size: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 30px;
}

.blog_Items .slick-track {
    height: 360px !important;
    left: -4px !important;
}


.blog_Items .slick-prev {
    left: 25px;
    z-index: 9;
}

.blog_Items .slick-next {
    right: 25px;
}

@media (max-width: 1389px) {

    .slider_S {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .slider_S_Tblog {
        height: 200px;
    }
}

@media (max-width: 1000px) {

    .slider_S_Tblog {
        height: 175px;
    }

    .foo_C_blog {
        font-size: 14px;
    }

    .blog_BTN {
        width: 43px !important;
    }

    .bLOG_H_Su {
        font-size: 16px;
    }
}

@media (max-width: 575px) {

    .slider_S_Tblog {
        height: 200px;
    }

    .foo_V_blog {
        font-size: 15px;
    }

    .slider_S_blog {
        grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
        gap: 12px;
    }

    .blog_Items .slick-slider {
        height: 340px !important;
    }

    .blog_Items .slick-track {
        height: 50vh !important;
    }
}

@media (max-width: 420px) {

    .foo_C_blog {
        font-size: 12px;
    }

    .slider_S_Tblog {
        height: 175px;
    }

    .slick-slide .slick-active .slick-current {
        width: 225px !important;
    }

    .bLOG_H_Su {
        font-size: 14px;
    }
}