.detials {
    display: flex;
    flex-direction: column;
    /* height: 500px;
    overflow-y: hidden; */
    width: 100%;
    /* padding-bottom: 50px; */
}

.individual-account-search .p-button .p-button-icon {
    font-weight: 500 !important;
}

.data-more-info {
    width: 155vh;
    height: 500px;
}

.edit-pen {
    background-color: none;
}

.dialog-box {
    height: 500px;
}

#pending-list-tab {
    height: 400px !important;
}

#MI:hover {
    background-image: none !important;
    background-color: transparent !important;
    color: navy !important;
}

#MI-1 {
    background-image: none !important;
    background-color: transparent !important;
    color: navy !important;
}

.head {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #495057;
    gap: 5px;
}

.pend-count-styl {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-size: 20px;
    border: 2px solid #124A99;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
}

.heading {
    white-space: normal !important;
    color: #3f51b5;
    margin: 0px;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.head_T {
    background: var(--gray-50);
    border: 1px solid rgb(209, 209, 209);
    height: 50px;
    padding: 0 20px;
}

@media (max-width: 800px) {
    .sing_up_main_Dialog {
        width: 30rem !important;
    }
}

@media (max-width: 600px) {
    .beneficiary .p-accordion-content {
        height: 30vh;
        overflow: auto;
    }

    .A_uto .p-accordion-content {
        height: auto !important;
    }

    .detials {
        position: relative;
        top: 50px;
    }

    .sing_up_main_Dialog {
        width: 80% !important;
    }
}