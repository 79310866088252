.sansation-upload {
    border: 2px dotted #555;
    padding: 10px;
    border-radius: 10px;
}

.custom-file-upload {
    background-color: transparent;
    color: #124A99;
    cursor: pointer;
}

.up-but {
    margin-left: 10px;
}

#sanc-updte {
    border: 1px solid rgb(211, 215, 225) !important;
    border-radius: 6px !important;
    font-family: Inter;
}

#sanc-updte .p-inputtext {
    color: rgba(42, 55, 89, 1) !important;
    font-size: 14px;
    font-weight: 500;
}

#sanc-upd .p-dropdown .p-dropdown-label.p-placeholder {
    /* font-family: Inter; */
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(42, 55, 89, 1) !important;
}

.drop-file-sanc {
    /* font-family: Inter; */
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.chs-file-txt {
    font-size: 16px !important;
    color: rgba(145, 145, 145, 1);
    font-weight: 500;
}

#file-oct {
    font-size: 14px !important;
    font-weight: 500 !important;
}

/* .custom-file-upload:hover {
    background-color: #3f3d6e;
} */

/* .custom-file-upload:active {
    background-color: #124A99;
} */
#fileInput[type="file"] {
    display: none;
}

.custom-file-upload:before {
    content: "\f0ee";
    font-family: PrimeIcons;
    font-size: 16px;
}

[dir="rtl"] .custom-file-upload:before {
    margin-right: 0;
}

.step-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-item-container {
    display: flex;
    align-items: center;
    gap: 25px;
}

.step-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    font-size: 14px;
    margin: 0;
    position: relative;
}

.step-item:hover {
    background-color: none;
    color: rgb(42, 55, 89);
}

.step-item.active-on {
    background-color: rgb(42, 55, 89);
    color: #fff;
    font-weight: bold;
}

.step-line {
    position: absolute;
    top: 50%;
    left: 30px;
    width: 100%;
    height: 2px;
    background-color: #ccc;
}

.step-line::before {
    content: '';
    position: absolute;
    top: -4px;
    /* width: 335%; */
    height: 12px;
    background-color: #bfc0c2;
    display: none;
}

.step-line.active-line::before {
    display: block;
}

.dropdown-option {
    color: white !important;
}

.fixed-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.alias-add-but {
    display: flex;
}

.header {
    padding: 10px 16px;
    background: #555;
    color: #f1f1f1;
    transition: top 0.3s ease-in-out;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.step-container {
    height: 400px;
    overflow-y: scroll !important;

}

.custom-button i {
    color: white !important;
    font-size: 14px !important;
}

#sanction-updates-step-indicator .p-calendar .p-inputtext {
    /* border-color: transparent !important; */
}

.radioBtn .p-radiobutton-box.p-highlight {
    border-color: #124A99 !important;
    background: #124A99 !important;
}

.radioBtn .p-focus {
    box-shadow: 0 0 0 0.2rem #124A994a !important;
}

@media (max-height: 500px) and (min-height:320px) {
    .mainSec .condent_sec.height-max-vh {
        width: 100%;
        height: 24vh !important;
        overflow: auto;
    }
}
@media (max-height: 600px) and (min-height:500px) {
    .mainSec .condent_sec.height-max-vh {
        width: 100%;
        height: 39vh !important;
        overflow: auto;
    }
}
@media (max-height: 800px) and (min-height:600px) {
    .mainSec .condent_sec.height-max-vh {
        width: 100%;
        height: 51vh !important;
        overflow: auto;
    }
}
@media (max-height: 950px) and (min-height:800px) {
    .mainSec .condent_sec.height-max-vh {
        height: 63vh !important;
        overflow: auto;
        width: 100%;
    }
}
@media (max-width: 575px) {
    .alias-btn .p-button {
        height: 35px !important;
    }

    .chs-file-txt {
        font-size: 12px !important;
        text-align: center;
    }

    .feild-Sec .flex .a1 {
        width: auto;
        margin: unset;
    }

    .mobV {
        flex-direction: row !important;
        justify-content: center;
        height: 4vh;
    }

    .al {
        position: relative;
        top: 40px;
        width: -webkit-fill-available;
        background: #fff;
        z-index: 9;
    }

    .f_p {
        position: relative;
        top: 20px;
    }

    .dropdown,
    .al {
        position: relative !important;
        top: 0 !important;
    }

    .w-6 {
        width: 75% !important;
    }
}