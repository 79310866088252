.finance-pdng-akg .p-dialog-header {
   display: none !important;
}

.pndg-ackg-form-text h1 {
   font-size: 18px !important;
}

.pndg-ackg-form-text p {
   font-size: 12px !important;
}

.pndg-ackg-form-text label {
   font-size: 12px !important;
}

.pndg-ackg-form-text .p-inputtext {
   font-size: 11px !important;
}

.pndg-ackg-form-text h4,
.pndg-ackg-form-text h3 {
   font-size: 12px !important;
}

#sus-in {
   font-size: 15px !important;
}

.p-float-label input:focus~#sus-in,
.p-float-label input:-webkit-autofill~#sus-in,
.p-float-label input.p-filled~#sus-in,
.p-float-label textarea:focus~#sus-in,
.p-float-label textarea.p-filled~#sus-in,
.p-float-label .p-inputwrapper-focus~#sus-in,
.p-float-label .p-inputwrapper-filled~#sus-in,
.p-float-label .p-tooltip-target-wrapper~#sus-in {
   font-size: 12px !important;
}

.t-d-1 {
   font-size: 13px !important;
   /* width: auto !important; */
}

.a1f1 {
   white-space: nowrap !important;
}

.transaction-details.formgrid.grid .col-12 {
   width: 50% !important;
}

.tab-txt {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;

   color: rgb(42, 55, 89);
   font-size: 18px;
   border: 1px solid #124A993d;
   margin: auto;
   height: 50px;
}
