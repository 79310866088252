.chooseUs_Main {
    padding: 50px 25px;
    position: relative;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: #F9F8FF;
}

.chooseUs_Sec {
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.chooseUs_Sec_R {
    width: 60%;
    height: 350px;
    display: flex;
}

.chooseUs_Sec_L {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fLEX {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.choose_US {
    width: 226px;
    height: 55px;
    border-radius: 30px;
    color: #093EA4;
    background: #FAFAFA;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
}

.chooseUs_Sec_N {
    font-size: 20px;
    font-weight: 600;
    line-height: 45px;
    color: #858585;
    text-align: justify;
    display: flex;
    justify-content: center;
    margin: unset;
}

.chouseUs_Rea {
    width: auto;
    height: 150px;
    display: flex !important;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background: #ffff;
    cursor: pointer;
    padding: 20px;
    text-align: center;
}

.slide_He {
    color: #093EA4;
    font-weight: 600;
    font-size: 20px;
}

.slide_No {
    font-weight: 500;
    font-size: 18px;
    color: #ffff;
    text-align: justify;
}

.blog-item {
    cursor: pointer;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-item:hover {
    z-index: 9;
    background-color: #093EA4;
}

.blog-item:hover .slide_He,
.blog-item:hover .nONE div svg {
    color: #ffff;
}

.blog-item:hover .hover-info,
.blog-item:hover .nONE {
    background-color: #093EA4;
}

.hover-info {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: -1;
}

.blog-item:hover .hover-info,
.blog-item:hover .nONE {
    opacity: 1;
    transform: translateY(0);
    z-index: 1;
    top: 125px;
    border: unset;
    border-radius: unset;
    height: 150px !important;
    overflow: auto !important;
}

.slider-container {
    width: 800px;
}

.slide {
    height: 275px;
}

.slick-dots li button:before {
    font-size: 13px !important;
    color: #093EA4 !important;
}

.slick-dots li button,
.slick-dots li.slick-active button:before {
    color: #093EA4 !important;
    opacity: 1 !important;
}

.chooseUs_Blog .slick-track {
    height: 300px !important;
}

.cho_US_Sec_Main {
    /* width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: normal;
    flex-wrap: wrap;
    gap: 25px; */

    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
    gap: 25px;
}

.cho_US_Sec {
    width: 335px;
    height: auto;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 35px 15px;
    border: 1px solid #C6C6C6;
    border-radius: 5px;
    gap: 20px;
    cursor: pointer;
}

.cho_US_Sec_B {
    width: 70px;
    height: 70px;
    background: #B9B9B9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.h2_Cho {
    width: 200px;
    height: 60px;
    font-size: 25px;
    font-weight: 600;
    color: #093EA4;
    text-align: left;
    display: flex;
    align-items: center;
}

.iCO_N {
    color: #4E4E4E;
    font-size: 30px;
}

.h2_Cho,
.p_Cho {
    margin: unset;
}

.p_Cho {
    line-height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #858585;
}

.cho_US_Sec:hover {
    background: #093EA4;
}

.comprehensive_B,
.comprehensive_W {
    height: 40px;
}

.comprehensive_W {
    display: none;
}

.cho_US_Sec:hover .comprehensive_W {
    display: flex;
}

.cho_US_Sec:hover .comprehensive_B {
    display: none;
}

.cho_US_Sec:hover .h2_Cho,
.cho_US_Sec:hover .p_Cho,
.cho_US_Sec:hover .iCO_N {
    color: #FFFFFF;
}

.cho_US_Sec:hover .cho_US_Sec_B {
    background: #ffffff85;
}




@media (max-width: 1200px) {
    .chooseUs_Sec_N {
        font-size: 18px;
    }
}

@media (max-width: 745px) {
    .cho_US_Sec_Main {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: normal;
        flex-wrap: wrap;
        gap: 25px;
    }
}

@media (max-width: 1031px) {
    .chooseUs_Sec_N {
        font-size: 16px;
    }
}

@media (max-width: 1290px) {
    .chooseUs_Sec_L {
        width: 100%;
    }

    .fLEX {
        flex-direction: column;
        gap: 15px;
    }

    .slider-container {
        margin: auto;
    }
}

@media (max-width: 800px) {
    .chooseUs_Sec {
        width: 100%;
    }

    .slider-container {
        width: 100%;
    }

    .chooseUs_Sec_N {
        line-height: 32px;
    }

    .slide_He,
    .slide_No {
        font-size: 16px;
    }

}

@media (max-width: 575px) {

    .chooseUs_Sec_N {
        line-height: 22px;
    }

    .slide_He,
    .slide_No {
        font-size: 14px;
    }

    .chouseUs_Rea {
        height: 100px;
    }

    .blog-item:hover .hover-info,
    .blog-item:hover .nONE {
        transform: unset;
        top: 90px;
    }

    .hover-info {
        opacity: 1;
        transform: translateY(0);
        z-index: 1;
        top: 93px;
        border: unset;
        border-radius: unset;
        height: 146px !important;
        overflow: auto !important;
        padding: 35px;
    }

    .chooseUs_Blog .slick-slider {
        height: 200px !important;
    }


    .blog-item {
        background-color: #093EA4;
    }



    .slide_He,
    .nONE div svg {
        color: #ffff;
    }

    .hover-info,
    .nONE {
        background-color: #093EA4;
    }

}

@media (max-width: 420px) {

    .cho_US_Sec {
        width: 100%;
    }
}