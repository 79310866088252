/*   Transaction Criteria Alert Scores css styles  */

.scores-card {
    box-shadow: none !important;
}
.highrisk-board{
    border-bottom: 4px solid red !important;
}
.mediumrisk-board{
    border-bottom: 4px solid green !important;
}
.trust-board{
    border-bottom: 4px solid #2196f3 !important;
}
.designation-board{
    border-bottom: 4px solid #84edc5 !important;
}
.country-board{
    border-bottom: 4px solid #f68175 !important;
}
.corporate-board{
    border-bottom: 4px solid #fe9707 !important;
}
.highrisk-board,
.mediumrisk-board,
.trust-board,
.designation-board,
.country-board,
.corporate-board {
    box-shadow: 0 0px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
    padding: 10px !important;
}
.transaction-criteria p{
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    align-items: center !important;
}
.in-mt-bn,.dc-mt-bn{
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    background-color: transparent;
    border: none;
    color: rgba(145, 145, 145, 1);
    cursor: pointer;
 }
 .in-mt-bn .pi,.dc-mt-bn .pi{
    font-size: 12px;
    font-weight: bold;
 }
 .bts-7{
    display: flex !important;
    justify-content: center !important;
    gap:10px !important;
 }
 .transaction-criteria .p-card .p-card-body {
    padding-right: 0rem !important;
    padding-top: 0rem !important;
 }
 .knob-txt-content1{
    padding-right: 1rem !important;
 } 
.parass{
    padding-right: 10px !important;
    font-size: 16px !important;
}

@media (max-width: 1400px) {
    .alert-criteria {
        grid-template-columns: repeat(2, 1fr);
    } 
    .score-board .parass{
        font-size: 16px !important;
    } 
}
@media (min-width:1000px) and (max-width: 1160px) {
    .highrisk-board,
    .mediumrisk-board,
    .trust-board,
    .designation-board,
    .country-board,
    .corporate-board {
        /* height: 40vh; */
        padding-top: 0px !important;       
    }   
    .scores-card .flex{
        display: flex !important;
    }
     .score-board {
        width: 100% !important; 
    }
}

@media (min-width:951px) and (max-width: 1000px) {
    .scores-card .flex{
        display: flex !important;
    }
     .score-board {
        width: 100% !important; 
    }
}

@media (max-width: 950px) {
    .alert-criteria {
        grid-template-columns: repeat(2, 1fr);
    }

    .score-board {
        width: 100%; 
    }
    .scores-card .flex{
        display: flex !important;
    }
}

@media (max-width: 360px) {
    .scores-card .flex{
        display: grid !important;
        justify-content: normal !important;
    }   
    .parass{
        padding-right: 0px !important;
    } 
}

@media (max-width: 500px) {
    .alert-criteria {
        grid-template-columns: repeat(2, 1fr);
    }
    .score-board {
        width: 100%; 
    }      
}

@media (min-width: 320px) and (max-width: 500px){     
    .upd-button{
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    .loading-button .p-button .p-button-icon {
      font-size: 14px !important;
      padding-left: 0% !important;
      padding-right: 0% !important;
    }
}

/*         parass font-sizes  media query    */
@media (max-width: 1023px){
    .score-board .parass{
        font-size: 15px !important;
    }
}

@media (max-width: 749px){ 
    .score-board .parass{
        font-size: 15px !important;
    }
}
@media (min-width: 320px) and (max-width: 575px){
    .score-board .parass{
        font-size: 14px !important;
    }
}
