.calendar {
    width: 100%;
    height: 40px;
}

.breanch-dialog .p-dialog-header {
    background-color: #124A99 !important;
}

.breanch-dialog .p-dialog-footer {
    padding: unset;
}

#range .p-inputtext {
    border: none !important;
}

.card-beneficiary {
    width: 100%;
    margin-left: 0%;
    margin-right: 2.5%;
}

.unique-datatable-mobs.p-datatable>.p-datatable-wrapper {
    height: auto !important;
    max-height: 48vh !important;
}

.beneficiary-txt {
    padding-left: 4% !important;
}

.tran-search .p-button-icon {
    color: white !important;
}

.p-dialog-content {
    overflow: hidden;
}

.new-btn .p-button-icon {
    color: #091F41 !important;
}

.export-btn .p-button-icon {
    color: #091F41 !important;
}

.new-btn .p-button-icon:hover {
    color: #fff !important;
}

.export-btn .p-button-icon:hover {
    color: #fff !important;
}

.new-btn {
    border-radius: 50%;
    padding: 22px !important;
    background-color: #fff !important;
    box-shadow: 0 4px 5px #091F41;
}

.export-btn {
    border-radius: 50%;
    padding: 21.5px !important;
    background-color: #fff !important;
    box-shadow: 0 4px 5px #091F41;
}

.pl-4 {
    margin-left: 50px;
}

.check {
    height: 500px;
}

#trans-edit {
    padding: 0 !important;
    background-color: transparent !important;
}


.trans-action input:focus~label,
.trans-action input:-webkit-autofill~label,
.trans-action input.p-filled~label,
.trans-action textarea:focus~label,
.trans-action textarea.p-filled~label,
.trans-action .p-inputwrapper-focus~label,
.trans-action .p-inputwrapper-filled~label,
.trans-action .p-tooltip-target-wrapper~label {
    margin-top: 9px !important;
}

.reset {
    height: 20px !important;
    border-radius: 3px;
    cursor: pointer;
}

.reset .p-button-label {
    display: none;
}

.unique-mobile-fie .flex .input .p-inputtext {
    border-radius: 6px 0px 0px 6px !important;
}

.unique-mobile-fie .flex {
    gap: 15px !important;
}

.unique-mobile-fie {
    gap: 15px !important;
}

.reset-btn .p-button-icon {
    color: white;
    padding-left: 9%;
    font-weight: bolder;
}

.unique-drop-fie .p-multiselect:not(.p-disabled):hover {
    border: 1px solid #ced4da !important;
}

.unique-datatable-mobs {
    border-top: none;
    border-right: 1px solid rgb(209, 209, 209);
    border-bottom: 1px solid rgb(209, 209, 209);
    border-left: 1px solid rgb(209, 209, 209);
    border-image: initial;
}

.unique-pi-icons .p-button-icon {
    color: white;
}

.unique-pi-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.unique-pi-icons .p-button {
    border: 1px solid rgb(42, 55, 89);
    /* height: 0px !important; */
}

.selectButton .p-highlight {
    background: #124A99 !important;
    border: unset;
    color: #fff;
}

.p-dialog .p-dialog-header .p-dialog-title {
    text-align: justify !important;
}

@media (max-width: 500px) {
    .unique-pi-icons .p-button-icon {
        color: white;
        font-weight: normal;
    }

    .unique-pi-iconss .p-button-icon {
        color: white;
        font-weight: normal;
    }

    .tran .p-breadcrumb {
        z-index: 9;
        position: fixed;
        width: -webkit-fill-available;
    }

}

@media (max-height: 600px) and (min-height:400px) {
    .menuItem .mainSec.tran {
        width: -webkit-fill-available;
        height: 73vh;
        position: fixed;
        top: 137px;
        overflow: auto;
    }
}

@media (max-height: 400px) and (min-height:220px) {
    .menuItem .mainSec.tran {
        width: -webkit-fill-available;
        height: 59vh;
        position: fixed;
        top: 121px;
        overflow: auto;
    }
}

@media (max-width: 575px) {
    .tran .p-breadcrumb {
        z-index: 9;
        position: fixed;
        width: -webkit-fill-available;
    }

    .mob-view-hides {
        display: none !important;
    }

    .unique-mobile-fie .flex {
        flex-direction: column !important;
    }

    .unique-swap {
        padding-top: 10px !important;
        gap: 0px !important
    }

    .unique-con {
        margin-top: 43px !important;
    }

    .tran .feild {
        position: relative;
        top: 50px !important;
    }
}