.breadC {
    border: none;
    position: fixed;
    z-index: 9;
    width: -webkit-fill-available;
}

.pending_List {
    width: 100%;
    height: 80vh;
    display: flex;
    gap: 20px;
    /* justify-content: space-between; */
    flex-direction: column;
    overflow: auto;
}


@media (max-height: 524px) and (min-height:360px) {
    .pending_List.height-pending-vh {
        width: 100%;
        height: 52vh;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        flex-direction: column;
        overflow: auto;
    }
}
@media (max-height: 600px) and (min-height:524px) {
    .pending_List.height-pending-vh {
        width: 100%;
        height: 68vh;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        flex-direction: column;
        overflow: auto;
    }
}
@media (max-height: 800px) and (min-height:600px) {
    .pending_List.height-pending-vh {
        width: 100%;
        height: 71vh;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        flex-direction: column;
        overflow: auto;
    }
}

@media (max-width: 1250px) {
    .pending_List .sec {
        flex-direction: column;
    }
}