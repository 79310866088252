.header-maintain {
  background-color: #f9f8ff;
  width: 100%;
}

button {
  cursor: pointer;
}

.scrollToTopButton {
  position: fixed;
  bottom: 50px;
  right: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #093EA4;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.al_N {
  position: relative;
  top: 80px;
}

.p-datatable>.p-datatable-wrapper {
  max-height: 500px;
}