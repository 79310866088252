body {
  margin: 0;
  font-family: 'Inter', 'Helvetica', 'Arial', 'sans-serif';
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-multiselect-panel {
  width: 209px !important;
}

.max_W {
  max-width: 1400px;
  margin: auto;
}

.fLE_X {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.tex_A_Left {
  text-align: left;
}

.no_select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}