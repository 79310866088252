.blogItem_Sec {
    width: 100%;
    position: relative;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogItem_Sec_T {
    width: 100%;
    height: 700px;
    padding: 60px 50px;
    opacity: .8;
    display: flex;
    align-items: end;
    margin: auto;
    background-repeat: no-repeat;
}

.bj_IteMS {
    background-position: center center;
    background-size: cover;
}

.blog_Hea {
    width: 80vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #0000006b;
    padding: 25px 30px;
}

.blogItem_Sec_T_H {
    font-weight: 600;
    font-size: 35px;
    color: #fff;
}

.blogItem_Sec_T_S {
    color: gray;
    font-size: 24px;
}

.blogItem_Sec_C {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    gap: 25px;
}

.blog_Note {
    /* background-color: #262626; */
    border: 2px solid #124A99;
    color: #000;
    border-radius: 10px;
    line-height: 1.6;
    padding: 2.5rem;
}

.blog-view-body .blog_Note_Hed {
    color: #4e4e4e;
    font-size: 25px;
    font-weight: 600;
}

.blog_Foo_ {
    display: flex;
    gap: 15px;
}

.blog_Foo_BTN {
    padding: unset !important;
    font-size: 25px !important;
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
    display: flex;
}



@media (max-width: 575px) {
    .blogItem_Sec_C {
        padding: 25px;
    }

    .blog_Note {
        font-size: 14px;
    }

    .blog_Foo_BTN {
        font-size: 20px !important;
        width: 41px !important;
        height: 40px !important;
    }

    .blogItem_Sec_T {
        height: 45vh;
        padding: 25px;
    }

    .blogItem_Sec_T_H {
        font-size: 25px;
    }

    .blog_Hea {
        width: 100%;
    }
}

@media (max-width: 375px) {
    .blog_Note {
        padding: 20px;
    }
}