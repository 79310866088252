#cont-scrl {
    height: 42vh !important;

}

.p-avatar.p-component.p-avatar-image.p-avatar-circle.p-avatar-xl.up-avtr.h-9rem.overflow-hidden.mb-2 {

    width: 9rem !important;
}

.feild-main-class {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    flex-wrap: wrap;
}

.feild.c.pt-3.pb-3 {
    top: 0px !important;
    position: relative;
    padding: 10px !important;
    display: flex;
    padding-bottom: 30px !important;
    gap: 28px !important;
}

div#user-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: stretch;
    width: 50%;
}

.user-avatar.mb-3 {
    height: 50%;
    width: auto;
}

.phoneFeild,
.phoneFeild .form-control {
    height: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    color: #495057;
    background: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px !important;
    font-weight: normal;
}

input.p-inputtext.p-component.p-filled.address.address {
    height: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px !important;
    color: #495057;
    background: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px !important;
    font-weight: normal;
}

input.p-inputtext.p-component.p-disabled {
    height: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px !important;
    color: #495057;
    background: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px !important;
    font-weight: normal;
}

input.p-inputtext.p-component.p-filled.fieldsa {
    height: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px !important;
    color: #495057;
    background: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px !important;
    font-weight: normal;
}

span.p-dropdown-label.p-inputtext {
    font-size: 16px;
}

@media (max-width: 575px) {
    .card.card-Adhoc.w-full.abcd {
        position: relative;
        margin: 0px !important;
        top: -9px;
    }
}

@media (max-width: 500px) {

    .phoneFeild,
    .phoneFeild .form-control {
        height: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px !important;
        color: #495057;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px !important;
        font-weight: normal;
    }

    input.p-inputtext.p-component.p-filled.address.address {
        height: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px !important;
        color: #495057;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px !important;
        font-weight: normal;
    }

    span.p-dropdown-label.p-inputtext {
        font-size: 13px;
    }

    input.p-inputtext.p-component.p-disabled {
        height: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px !important;
        color: #495057;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px !important;
        font-weight: normal;
    }

    input.p-inputtext.p-component.p-filled.fieldsa {
        height: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px !important;
        color: #495057;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px !important;
        font-weight: normal;
    }
}

@media (min-width: 500px) and (max-width: 700px) {

    .phoneFeild,
    .phoneFeild .form-control {
        height: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px !important;
        color: #495057;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px !important;
        font-weight: normal;
    }

    input.p-inputtext.p-component.p-filled.address.address {
        height: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px !important;
        color: #495057;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px !important;
        font-weight: normal;
    }

    span.p-dropdown-label.p-inputtext {
        font-size: 14px;
    }

    input.p-inputtext.p-component.p-disabled {
        height: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px !important;
        color: #495057;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px !important;
        font-weight: normal;
    }

    input.p-inputtext.p-component.p-filled.fieldsa {
        height: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px !important;
        color: #495057;
        background: #ffffff;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px !important;
        font-weight: normal;
    }
}

@media (min-width: 576px) and (max-width: 749px) {
    .card.card-Adhoc.w-full {
        position: relative;
        margin: 0px !important;
        top: 0px !important;
    }
}

@media (max-width: 500px) {
    .feild-d .flex .input {
        width: 100% !important;
        margin: unset;
    }
}

@media (min-width: 320px) and (max-width: 767px) {

    .Adthoc-input1 {
        padding-left: 0% !important;
        padding-right: 0% !important;
    }

    #cont-scrl {
        padding-top: 0rem !important;
    }
}

@media (min-width: 500px) and (max-width: 575px) {
    .c .flex {
        flex-direction: column;
    }
}

.footer_sec_btn_dialuge.user-reg {
    width: 100%;
    height: 40px;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    padding: 0 5px;
}

@media (max-height: 378px) {
    .menuItem .mainSec.userprofile {
        height: 77vh !important;
        overflow: auto;
    }
    .menuItem .mainSec.inform {
        width: -webkit-fill-available;
        height: 67vh;
        position: fixed;
        top: 121px;
        overflow: auto;
    }
}
@media (max-width: 880px) {
    .two-field-tab-responce {
        display: none !important;
    }

    .acvd-responce-border {
        border: 0px solid !important;
    }
}